.alert-overlay{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    margin: 1rem auto;
    /* left: 50%; */
    transform: translate(-50%, 0);
    width: 95vw;
    max-width: 30rem;
}

.alert-modal{
    width: 100%;
    max-width: var(--width-section);
    background-color: var(--black-mid);
    border: 2px solid var(--gray-900);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    padding: 1rem;
    transition: var(--transition-2);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 0px 30px rgba(0, 0, 0, 0.1);

    position: relative;

    border-radius: var(--radius);
    
}

.alert-modal .alert-modal-icon-container{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--gray-50);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid var(--gray-50);
    outline-offset: 10px;
    margin: 1rem 0 1.5rem 0;
}

.alert-modal .alert-modal-icon-container i{
    color: var(--gray-900);
    font-size: 1.5rem;
}

.alert-modal .fa-xmark{
    color: var(--gray-900);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 1.75rem;
    cursor: pointer;
    transition: var(--transition-2);
}

.alert-modal .fa-xmark:hover{
    color: var(--red-mid);
}

.alert-modal-type{
    font-size: 1.25rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    font-family: var(--ff-heading);
    font-weight: 600;
    color: var(--primary-600);
}

.alert-modal-type-warning, .alert-modal-type-warning-exam{
    color: var(--yellow-mid);
}


.alert-modal-type-error{
    color: var(--red-mid);
}

.alert-modal-type-success{
    color: var(--green-dark);
}

.alert-modal-title{
    color: var(--primary-500);
    font-family: var(--ff-heading);
    text-transform: capitalize;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: var(--letter-spacing);
}

.alert-modal-description-regular{
    color: var(--gray-50)!important;
    font-family: var(--ff-body);
    text-transform: capitalize;
    font-size: 1rem!important;
    font-weight: 600!important;
    letter-spacing: var(--letter-spacing)!important;
}

.fade-in-bottom-alert-only { 
    opacity: 0;
    transform: translate(-50%, -10vh);
    transition: all 1s;
    z-index: -10000!important;
}

.fade-in-bottom-alert-only.visible {
    opacity: 1;
    transform: translate(-50%, 0);
    z-index: 10000!important;
}

#closeModal{
    color: var(--gray-50)!important;
}

#closeModal:hover{
    color: var(--red-mid)!important;
}