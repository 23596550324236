.footer {
  background-color: var(--black-mid);
  color: var(--gray-50);
  text-align: center;


  position: relative;
  z-index: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  display: grid;
  grid-template-rows: repeat(3, min-max(200px, 1fr));
  grid-template-columns: repeat(1, 1fr);
}

.footer-text-fade-in-right{
  opacity: 0;
  transform: translateX(-10vh);
  transition: all 1s;
}

.footer-text-fade-in-right.visible {
  opacity: 1;
  transform: translateX(0);
}

.footer-img{
  margin-bottom: 1rem!important;
  width: 100%!important;
  height: 100%;

  text-align: center;
  display: flex!important;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: translateX(-10vh);
  transition: all 1s;
}

.footer-img.visible {
  opacity: 1;
  transform: translateX(0);
}

.footer-img img{
  width: 100%;
  min-width: 45px!important;
  max-width: 100px;
  height: 100%;
}

.row-1{
  padding: 1rem;
}

.row-2{
  padding: 0.5rem;
  display: grid;
  /* grid-template-rows: repeat(4, auto); */
  grid-template-rows: repeat(1, auto);
  gap: 1rem 0;
}

.row-3{
  padding: 0.5rem;
}

.footer-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.footer-banner-specific{
  color: var(--primary-500);
}

.footer-banner h1, .footer-banner h2 {
  color: var(--gray-50);
  font-family: var(--ff-heading);
  letter-spacing: var(--letter-spacing);
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 1rem;
}

.footer-banner h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.footer-banner p {
  color: var(--gray-400);
  font-family: var(--ff-body);
  letter-spacing: var(--letter-spacing);
  line-height: 1.25;
  max-width: 35rem;
  margin: 0;
  margin: 0 auto;
}

.footer-sitemap-links, .footer-documents-links{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-sitemap-links p, .footer-documents-links p{
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-family: var(--ff-heading);
  color: var(--primary-400);
}

.footer-sitemap-list, .footer-documents-list{
  list-style-type: none;
  margin: 0;
}

.footer-link{
  font-family: var(--ff-body);
  font-size: 1rem;
  color: var(--gray-50);
}

.footer-link:hover{
  color: var(--primary-300);
  text-decoration: none;
}

.footer-reviews{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-reviews p{
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-family: var(--ff-heading);
  color: var(--primary-400);
}

.hide-review{
  display: none;
}

.show-review{
  /* display: block; */
  font-size: 3rem;
}

.review-date{
  margin: 0!important;
  font-size: 1rem!important;
}

.footer-reviews-ball{
  color: var(--gray-200);
  max-width: 26rem;
  margin: 0 auto;
  width: 18rem;
  height: 18rem;
  background-color: var(--gray-600);
  border-radius: 50%;

  display: grid;
  grid-template-columns: 10% 80% 10%;
  overflow: hidden;
}


.carousel-control{
  background: transparent!important;
  color: var(--gray-900);
  box-shadow: none;
  text-shadow: none;
  opacity: 1;
  -webkit-filter: none;
          filter: none;
  width: 100%;
  font-size: 2rem;
  position:inherit;
}

.carousel-control:hover{
  color: var(--gray-50);
}

.footer-reviews-ball-column-1{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
}

.footer-reviews-ball-column-2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-reviews-ball-column-3{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
}

.footer-reviews-ball-column-2 h2{
  margin: 0;
  font-size: 1.5rem;
  font-family: var(--ff-heading);
  color: var(--gray-50);
}

.footer-reviews-ball-column-2 p{
    color: var(--gray-50);
    font-family: var(--ff-body);
    letter-spacing: var(--letter-spacing);
    line-height: 1.25;
    max-width: 13rem;
    margin: 0;
    margin: 0 auto;
    font-size: 0.9rem;
}

.review-button{
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 2.5rem;
  color: var(--black-mid);
  transition: var(--transition-2);
}

.review-button:focus{
  outline: none;
}

.review-button:focus-visible{
  outline: none;
}

.review-button:hover{
  color: var(--gray-50);
}

.reviews-profile-img{
  width: 90%;
  height: auto;
  border-radius: 50%;
}

.review-stars{
  font-size: 1.5rem;
}

.full-star{
  color: gold!important;
}

.empty-star{
  color: gold!important;
}

.input-line {
  height: 2px;
  background: var(--black-mid);
  width: 4rem;
  margin-left: 1rem;
  z-index: 5;
  position: absolute;
}

.input-line-hebrew {
  width: 6rem;
}

.footer-label {
  display: block;
  font-size: var(--smallText);
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  position: absolute;
  left: 1.5rem;
  top: -1.1rem;
  transition: 0.2s ease-in-out top;
  pointer-events: none;
  z-index: 10;
}


.footer-input {
  color: var(--gray-50);
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--radius);
  border: 2px solid var(--gray-200);
  background-color: var(--color-bg);
  transition: var(--transition-2);
  margin-bottom: 1rem;
  letter-spacing: var(--letter-spacing);
  background-color: var(--gray-800);
}

.footer-input:focus-visible {
  outline: none;
}

.footer-input:hover + .footer-label {
  top: -1.35rem;
}
.footer-input:valid + .footer-label {
  top: -1.35rem;
  color: var(--green-mid);
}
.footer-input:focus + .footer-label {
  top: -1.35rem;
  color: var(--primary-300);
}

.footer-input:valid {
  outline: none;
  border: 2px solid var(--green-mid) !important;
  letter-spacing: var(--letter-spacing);
}

.footer-input:hover {
  outline: none;
  border: 2px solid var(--primary-400) !important;
  letter-spacing: var(--letter-spacing);
}

.input-line-center {
  margin: 0;
  width: 3.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-line-center .footer-label {
  position: initial;
  margin: 0;
  text-align: center;
}

.nform p{
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  font-family: var(--ff-heading);
  color: var(--primary-400);
}

.nform {
  margin: 0 auto;
  max-width: var(--width-section);
  width: 90vw;
}

#nemail{
  margin: 0;
}

.nform-submit input{
  border-radius: var(--radius-half-circle);
  margin: 0 auto;
  color: var(--gray-50);
}

.nform-submit input:focus{
  color: var(--gray-50);
}

.nform-submit input:hover{
  transform: scale(1.1);
}

.row-3 p{
  margin: 0;
  border-top: 2px solid var(--gray-800);
  padding-top: 0.5rem;
}

.newsletter-content{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title-newsletter{
  margin-bottom: 1.25rem!important;
}

.footer-form-alert-error{
  color: var(--red-mid)!important;
}

.footer-form-alert-success{
  color: var(--green-mid)!important;
}

@media screen and (min-width: 375px){
  .footer-reviews-ball{
    width: 21rem;
    height: 21rem;
  }

  .footer-reviews-ball-column-2 p{
    font-size: 1rem;
    max-width: 20rem;

  }
}

@media screen and (min-width: 425px){
  .footer-reviews-ball{
    width: 24rem;
    height: 24rem;
  }

  .footer-banner h1{
    font-size: 2.5rem;
  }

  .footer-banner h2{
    font-size: 2rem;
  }
  .footer-banner p{
    font-size: 1.25rem;
    max-width: 45rem;
  }

  .footer-reviews-ball-column-2 h2{
    font-size: 1.875rem;
  }
  
  .footer-reviews-ball-column-2 p{
    font-size: 1.25rem;
    max-width: 20rem;
  }

  .review-stars{
    font-size: 1.875rem;
  }

  .reviews-profile-img{
    width: 5rem;
    height: 3rem;
  }
}

@media screen and (min-width: 768px){
  /* .row-2{
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, 1fr);
  } */

  .footer-reviews{
    grid-column: 1 / -1;
  }

  .footer-reviews-ball{
    width: 26rem;
    height: 26rem;
  }

  .newsletter-content{
    grid-column: 1 / -1;
  }
}

@media screen and (min-width: 1024px) {
  /* .row-2{
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, 1fr);
  } */

  .newsletter-content{
    grid-column: 1 / -1;
  }

  .footer-reviews{
    grid-row: 1;
    grid-column: 2;
  }
  .newsletter-content{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / -1;
  }

  .footer-sitemap-links, .footer-documents-links{
    display: flex;
    justify-content: start;
    align-items: center;
  }
}

@media screen and (min-width: 1440px) {
  /* .row-2{
    display: grid;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: repeat(4, auto);
  } */



  .footer-reviews{
    grid-row: 1;
    grid-column: 3;
  }

  .newsletter-content{
    /* grid-column: 4; */
    grid-column: 3;
  }

  .nform {
    width: 80%;
  }

  .footer-sitemap-links, .footer-documents-links{
    display: flex;
    /* justify-content: center; */
    align-items: center;
  }
}