#examEndScreen {
  align-items: center;
  background-color: var(--gray-50);
  display: flex;
  justify-content: center;
  min-height: 100%;
  opacity: 1;
  position: fixed;
  width: 100vw;
  z-index: 5000;
  height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
}

.btn-end-screen {
  margin-bottom: 3rem !important;
}

.btn-end-exam {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border-color: var(--primary-500) !important;
}

.btn-end-exam:hover {
  background-color: var(--black-mid) !important;
  color: var(--gray-50) !important;
  border-color: var(--black-mid) !important;
}

#endScreenPanel,
#endScreenPanelSplit {
  height: 100%;
  text-align: center;
  width: 20rem;
}

#endScreenPanel h1,
#endScreenPanelSplit h1 {
  font-size: 2.5rem;
  color: var(--primary-500);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-weight: 600;
}

#endScreenPanel h3,
#endScreenPanelSplit h3 {
  font-size: 1.5rem;
  color: var(--primary-400);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-weight: 600;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pause-menu-title {
  color: var(--primary-400) !important;
  margin-top: 1rem;
}

#endScreenPanel p,
#endScreenPanelSplit p {
  font-size: 1.25rem;
  color: var(--gray-900);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  font-weight: 600;
  margin: 1.5rem 0 0.5rem 0 !important;
}

#endScreenPanel ul,
#endScreenPanelSplit ul {
  list-style: none;
  font-size: 1rem;
  color: var(--gray-900);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  font-weight: 500;
  font-family: var(--ff-body);
}

#endScreenPanel ul li span,
#endScreenPanelSplit ul li span {
  font-weight: 600;
}

.btn-start-screen:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border-color: var(--primary-500) !important;
}

.btn-start-screen2 {
  left: 50%;
  position: absolute;
  position: relative !important;
  transform: translate(-50%, 20%) !important;
  top: 20% !important;

  background-color: transparent !important;
  color: var(--red-mid) !important;
  border-color: var(--red-mid) !important;

  display: none !important;

  margin-top: 0rem !important;
}

.btn-start-screen2:hover {
  background-color: var(--red-mid) !important;
  color: var(--gray-50) !important;
  border-color: var(--red-mid) !important;
}

.btn-start-screen2-icon {
  position: absolute;

  background-color: transparent !important;
  color: var(--red-mid) !important;
  border-color: var(--red-mid) !important;

  left: 3%;
  top: 3%;
  transform: translate(-3%, -3%);
  font-size: 2rem;
}

.btn-start-screen2-icon:hover {
  background-color: var(--red-mid) !important;
  color: var(--gray-50) !important;
  border-color: var(--red-mid) !important;
}

#startExamBtn.gray-out-btn {
  cursor: not-allowed !important;
  background: var(--primary-700) !important;
  color: var(--gray-500) !important;
}

#startExamBtn.gray-out-btn:hover {
  cursor: not-allowed !important;
  background: var(--primary-800) !important;
  color: var(--gray-500) !important;
}

.questions-status-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

#loading-spinner-exam {
  display: flex !important;
}

.questions-status-header {
  font-weight: 600;
  letter-spacing: var(--letter-spacing);
  text-transform: uppercase;
  margin-top: 1rem;
}

#endScreenPanel .chosen-answer p,
#endScreenPanelSplit .chosen-answer p {
  margin: 0 !important;
  font-size: 0.75rem;
}

.reverse-chosen-answer {
  flex-direction: row-reverse;
}

.chosen-answer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.classification-list {
  margin: 0 !important;
}

.questions-status {
  background-color: var(--gray-100);
  border: 2px solid var(--gray-200);
  width: 95%;
  padding: 1rem;
  max-width: 30rem;
  max-height: 20rem;
  /* height: 18.3rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
}

.question-wrong {
  border: 2px solid var(--red-mid);
}

.question-wrong-p {
  color: var(--red-mid);
}

.question-correct {
  border: 2px solid var(--green-mid);
}

.question-correct-p {
  color: var(--green-mid);
}

.btn-finish-exam:hover {
  background-color: var(--red-mid) !important;
  color: var(--gray-50) !important;
  border-color: var(--red-mid) !important;
}

.btn-finish-exam {
  background-color: transparent !important;
  color: var(--red-mid) !important;
  border-color: var(--red-mid) !important;
}

@media screen and (min-width: 425px) {
  #endScreenPanel,
  #endScreenPanelSplit {
    width: 25rem;
  }

  #endScreenPanel h3,
  #endScreenPanelSplit h3 {
    padding-left: 0;
    padding-right: 0;
  }

  #endScreenPanel .chosen-answer p,
  #endScreenPanelSplit .chosen-answer p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 425px) {
  #endScreenPanel,
  #endScreenPanelSplit {
    width: 35rem;
  }
}

@media screen and (min-width: 768px) {
  .btn-start-screen2 {
    display: block !important;
  }

  .btn-start-screen2-icon {
    display: none;
  }

  .questions-status-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  .questions-status {
    min-width: 22rem;
    width: auto;
  }

  #examEndedData {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  #endScreenPanel,
  #endScreenPanelSplit {
    width: 95%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 1440px) {
  .questions-status-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
