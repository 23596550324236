.header {
  background-color: var(--gray-100);
  background: url('../../../images/background.jpg') center/cover no-repeat fixed;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
  url('../../../images/background.jpg') 10%/cover no-repeat fixed;
  color: var(--color-txt);
  text-align: center;

  min-height: 100vh;

  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  gap: 0.5rem 0;

}

.header-banner {
  width: 80%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header-banner h1 {
  color: var(--gray-50);
  color: var(--gray-800)!important;
  font-family: var(--ff-heading);
  letter-spacing: var(--letter-spacing);
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 1.5rem;
}

.header-banner-specific{
  color: var(--primary-400);
}

.header-img{
  text-align: center;
  display: flex!important;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: translateX(-10vh);
  transition: all 1s;
}

.header-img.visible{
  opacity: 1;
  transform: translateX(0);
}

.header-img img{
  width: 60%;
  min-width: 28px;
  max-width: 300px;
  padding-top: 3rem;
  margin-bottom: 2rem;
}

.header-btn-join-now{
  background-color: var(--primary-500)!important;
  padding: 0.5rem 0.75rem!important;
  font-size: 1rem;
  border-radius: 0.5rem!important;
  margin-top: 1.5rem;
  border: 2px solid var(--primary-500)!important;
  color: var(--gray-50)!important;
  font-family: var(--ff-heading);
  font-weight: 600!important;
}

.header-btn-join-now:hover{
  color: var(--primary-500)!important;
  background-color: var(--gray-200)!important;
  border-color: var(--gray-200)!important;
}

.header-btn-login{
  background-color: transparent!important;
  color: var(--primary-500)!important;
  font-size: 1rem;
  box-shadow: none!important;
  border-bottom: 1px solid transparent!important;
  margin: 0 auto;
}

.header-btn-login:hover{
  /* color: var(--primary-500)!important; */
  /* background-color: var(--gray-200)!important; */
  box-shadow: none!important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: var(--primary-300)!important;
  color: var(--primary-500)!important;
  transform: scale(1.1);
}

@media screen and (min-width: 425px) {
  .header-banner h1 {
    font-size: 2.25rem;
  }

  .header-btn-join-now{
    padding: 0.75rem 1rem!important;
    font-size: 1.25rem;
  }
  
  .header-btn-login{
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .header-banner h1 {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .header-banner h1 {
    font-size: 2.75rem;
  }
}

@media screen and (min-width: 1440px) {
  .header-banner h1 {
    font-size: 4rem;
  }

  .header-btn-join-now{
    font-size: 1.5rem;
  }
  
  .header-btn-login{
    font-size: 1.25rem;
  }
}