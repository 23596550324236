#examStopperTextHolder{
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

.examStopperText{
    background-color: var(--black-mid);
    color: var(--gray-50);
    padding: 0.5rem 0.5rem;
    border-radius: var(--radius);
    font-weight: 900;
}

.examStopperTextSep{
    color: var(--black-mid);
    font-weight: 900;
    padding: 0.5rem 0;
}

#wordTarget{
    user-select: none;
}

.questions-and-arrows{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 770px;
}

.study-arrows-container.show{
    display: flex;
}

.gray-out-button{
    color: var(--gray-400);
}

.next-btn-animation{
    animation: beep 1s ease-in-out 3;
}

@keyframes beep {
    0% { color: var(--gray-700); }
    50% { color: var(--primary-500); }
    100% { color: var(--gray-700); }
  }