#mainExams {
  padding: 1rem;
  width: 95%;
  margin: 0 auto;
  transition: var(--transition-2);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
}

.mainExams-exam {
  width: 100%;
  max-height: 35rem;
  min-height: 10rem;
  height: auto;
  max-width: 43.75rem;
  background-color: var(--gray-100);
  border: 3px solid var(--primary-400);
  border-radius: var(--radius);
  transition: var(--transition-2);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}

.short-answers-sep {
  color: var(--gray-900) !important;
}

#noExamsLoaded {
}

.btn-compare-now {
  margin: 1rem auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  /* left: 90%;
    position: absolute;
    top: 15%; */
}

.btn-compare-now:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
}

.compare-exams-icon {
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
  transition: var(--transition-2);
}

.compare-exams-icon i {
  font-size: 1.5rem;
  cursor: pointer;
  transition: var(--transition-2);
  color: var(--gray-900);
}

.compare-exams-icon i:hover {
  color: var(--primary-400);
}

.compare-exams-icon i.selected {
  color: var(--primary-500);
}

#noExamsLoaded h1 {
  text-align: center;
  letter-spacing: var(--letter-spacing);
}

.exam-ltr {
  direction: ltr !important;
}

.exam-rtl {
  direction: rtl !important;
}

.mainExams-exam:hover {
  transform: scale(1.01);
  border-color: var(--primary-500);
}

.mainExams-exan-top-parent {
  max-width: 15rem;
  width: 90%;
  height: 2rem;
  position: absolute;
  top: -32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
}

.mainExams-exam:hover .mainExams-exam-number,
.mainExams-exam:hover .mainExams-exam-unit2-parent,
.mainExams-exam:hover .mainExams-exam-date-parent,
.mainExams-exam:hover .mainExams-exam-topic-parent {
  background-color: var(--primary-500);
  color: var(--gray-50) !important;
}

.mainExams-exam:hover .mainExams-exam-timer-short-answers {
  opacity: 1;
}

.mainExams-exam-unit2-parent p,
.mainExams-exam-date-parent p,
.mainExams-exam-topic-parent p {
  margin: 0 !important;
  color: var(--gray-50) !important;
  font-size: 0.7rem;
}

.mainExams-exam:hover .mainExams-exam-unit2-parent p,
.mainExams-exam:hover .mainExams-exam-date-parent p,
.mainExams-exam:hover .mainExams-exam-topic-parent p {
  color: var(--gray-50) !important;
}

.mainExams-exam-top {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mainExams-exam-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mainExams-exam-bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.mainExams-exam-top p,
.mainExams-exam-center p,
.mainExams-exam-bottom p {
  margin: 0 !important;
  text-transform: capitalize;
}

.mainExams-exam-unit2-parent {
  display: flex;
  text-transform: capitalize;
  /* position: absolute; */
  margin: 0 !important;
  background-color: var(--primary-400);
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  /* left: 55%; */
  /* transform: translateX(-55%); */
  justify-content: center;
  align-items: center;
  transition: var(--transition-2);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.mainExams-exam-date-parent {
  display: flex;
  text-transform: capitalize;
  /* position: absolute; */
  margin: 0 !important;
  background-color: var(--primary-400);
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: center;
  align-items: center;
  transition: var(--transition-2);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.mainExams-exam-topic-parent {
  display: flex;
  text-transform: capitalize;
  /* position: absolute; */
  margin: 0 !important;
  background-color: var(--primary-400);
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  /* left: 100%; */
  /* transform: translateX(-100%); */
  justify-content: center;
  align-items: center;
  transition: var(--transition-2);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.mainExams-exam-topic,
.mainExams-exam-timer-stopper {
  color: var(--gray-600);
  transition: var(--transition-2);
}

.mainExams-exam:hover .mainExams-exam-topic,
.mainExams-exam:hover .mainExams-exam-timer-stopper {
  color: var(--gray-900);
}

.mainExams-exam:hover .mainExams-open-btn {
  background-color: var(--primary-500) !important;
}

.mainExams-open-btn {
  background-color: var(--primary-400) !important;
}

#mainExams .mainExams-exam .mainExams-open-btn:hover {
  background-color: transparent !important;
}

.mainExams-exam-number {
  position: absolute;
  margin: 0 !important;
  background-color: var(--primary-400);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: -20px;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transition-2);
}

.mainExams-exam-number:hover {
  background-color: var(--primary-500);
}

.mainExams-exam-number span {
  font-size: 1rem;
  margin: 0 !important;
  color: var(--gray-50);
}

.mainExams-open-btn {
  margin: 0 !important;
  text-transform: capitalize !important;
}

.mainExams-open-btn:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
}

.mainExams-more-btn {
  margin: 0 !important;
  text-transform: capitalize !important;
  background-color: var(--black-mid) !important;
  color: var(--gray-50) !important;
  border: 2px solid var(--black-mid) !important;
}

.mainExams-more-btn:hover {
  /* background-color: transparent!important; */
  /* color: var(--black-mid)!important; */
  transform: scale(1.05);
}

.mainExams-more-btn i {
  transition: var(--transition-2);
}

.mainExams-more-btn:hover i {
  transform: rotate(60deg);
}

.mainExams-exam-timer-short-answers span {
  font-size: 1.5rem;
}

.mainExams-exam-timer-short-answers {
  transition: var(--transition-2);
  opacity: 0.8;
}

#exam-1 {
  margin-top: 1rem;
}

.mainExams-exam-score,
.mainExams-exam-unit,
.mainExams-exam-timer-stopper,
.mainExams-exam-date,
.mainExams-exam-topic {
  font-size: 1.25rem;
}

.short-answers-correct {
  color: var(--green-dark);
}

.short-answers-incorrect {
  color: var(--red-mid);
}

.mainExams-exam-timer-short-answers2 {
  display: none !important;
}

.mainExams-exam-unit2-parent p i,
.mainExams-exam-date-parent p i,
.mainExams-exam-topic-parent p i {
  font-size: 1rem;
  display: none;
}

@media screen and (min-width: 768px) {
  .mainExams-exan-top-parent {
    max-width: 28rem;
  }

  .mainExams-exam-unit2-parent p,
  .mainExams-exam-date-parent p,
  .mainExams-exam-topic-parent p {
    font-size: 1rem;
  }

  .mainExams-exam-unit2-parent p i,
  .mainExams-exam-date-parent p i,
  .mainExams-exam-topic-parent p i {
    display: inline;
  }

  .mainExams-exam-top {
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .mainExams-exam-center {
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .mainExams-exam-bottom {
    /* justify-content: space-between; */
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }

  .mainExams-exam-unit {
    display: none;
  }

  .mainExams-exam-timer-short-answers {
    /* display: none; */
    margin: 0 auto;
  }

  .mainExams-exam-timer-short-answers2 {
    display: block !important;
  }
  #mainExams {
    gap: 4rem;
  }

  .mainExams-exam-date,
  .mainExams-exam-topic,
  .mainExams-exam-unit2,
  .mainExams-exam-score {
    letter-spacing: var(--letter-spacing);
  }
}

@media screen and (min-width: 1024px) {
  #noExamsLoaded {
    margin-left: auto !important;
    margin-right: auto !important;
    grid-column: 1/-1;
  }

  #mainExams {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .mainExams-more-btn {
    grid-column: 1 / -1;
    max-width: 20rem;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mainExams-exam {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
