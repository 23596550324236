/*
==============================
*   Globbal styles only!
*   Buttons, forms, inputs, etc.
==============================
*/

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'HeadingEnglish';
  src: local('Inter'), local('Heebo'), local('Open Sans'), sans-serif;
  unicode-range: U+0000-007F;
}

@font-face {
  font-family: 'HeadingHebrew';
  src: local('Inter'), local('Heebo'), local('Open Sans'), sans-serif;
  unicode-range: U+0590-05FF; 
}

@font-face {
  font-family: 'BodyEnglish';
  src: local('Open Sans'), sans-serif; 
  unicode-range: U+0000-007F; 
}

@font-face {
  font-family: 'BodyHebrew';
  src: local('Open Sans'), sans-serif; 
  unicode-range: U+0590-05FF;
}

*,
::before,
::after {
  /* Additional default web browser styles reset */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* Primary Color */
  --primary-50: #f1fbf8;
  --primary-100: #d1ddf8;
  --primary-200: #a3bbf1;
  --primary-300: #769ae9;
  --primary-400: #4878e2;
  --primary-500: #1A56DB; /* Default Primary Color */
  --primary-600: #1545af;
  --primary-700: #103483;
  --primary-800: #0a2258;
  --primary-900: #05112c;


  /* Gray Colors */
  --gray-50: #fafafa;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-700a: #37415199;
  --gray-800: #1f2937;
  --gray-900: #111827;

  /* Additional Colors */
  --white: #fff;
  --white-mid: #e7e7e7;
  --white-bone: #f9f6ee;

  --black: #0d0d0d;
  --black-mid: #1b1e27;
  --black-light: #333333;

  --red-light: #f8d7da;
  --red-mid: #f24646;
  --red-dark: #842029;

  --green-dark: #0f5132;
  --green-mid: #90ee90;
  --green-light: #d1e7dd;

  --yellow-dark: #856404;
  --yellow-mid: #ffc107;
  --yellow-light: #fff8e5;

  --blue-dark: #004085;
  --blue-mid: #0d6efd;
  --blue-light: #cce5ff;

  --orange: #fd7e14;
  --pink: #d63384;
  --purple: #6f42c1;

  /* Shadows */
  --shadow-1: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-2: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-3: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadow-4: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);

  /* Gradients */
  --gradient-opacity: 0.8;

  /* Text Styles */
  --ff-heading: 'HeadingEnglish', 'HeadingHebrew', sans-serif;
  --ff-body: 'BodyEnglish', 'BodyHebrew', sans-serif;
  --smallText: 0.7em;
  --color-bg: var(--gray-50);
  --color-txt: var(--gray-900);

  --letter-spacing: 0.1rem;
  --line-height: 1.5;

  /* Border Styles */
  --radius-circle: 50%;
  --radius-half-circle: 3.125rem;
  --radius: 0.5rem;

  /* Animations / Transitions / Scales */
  --transition-5: 0.5s ease-in-out all;
  --transition-2: 0.2s ease-in-out all;
  --transition-1: 0.1s ease-in-out all;

  /* Div Styles */
  --width-max: 1024px;
  --width-section: 550px;
}

h1, h2, h3, h4, h5, h6, p, span {
  cursor: default;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-bg);
  font-family: var(--ff-body);
  font-weight: 600;
  line-height: 1.75;
  color: var(--color-txt);
}

/* Start Of Custom Scroll Bar */
html::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

html::-webkit-scrollbar-thumb {
  background-color: var(--primary-300);
  /* border-radius: 10px; */
}

html::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* border-radius: 10px; */
}
/* End Of Custom Scroll Bar */
/* Start hide / show */
.hide {
  display: none !important;
}

.show {
  display: block !important;
}
/* End hide / show */
/* Start Of Section */
.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* to top */
.fade-in-top { 
  opacity: 0;
  transform: translateY(10vh);
  transition: all 1s;
}

.fade-in-top.visible {
  opacity: 1;
  transform: translateY(0);
}

/* to right */
.fade-in-right { 
  opacity: 0;
  transform: translateX(-10vh);
  transition: all 1s;
}

.fade-in-right.visible {
  opacity: 1;
  transform: translateX(0);
}

.text-fade-in-right{
  opacity: 0;
  transform: translateX(-10vh);
  transition: all 1s;
}

.text-fade-in-right.visible {
  opacity: 1;
  transform: translateX(0);
}

/* to bottom */
.fade-in-bottom { 
  opacity: 0;
  transform: translateY(-10vh);
  transition: all 1s;
}

.fade-in-bottom.visible {
  opacity: 1;
  transform: translateY(0);
}

/* to left */
.fade-in-left { 
  opacity: 0;
  transform: translateX(10vh);
  transition: all 1s;
}

.fade-in-left.visible {
  opacity: 1;
  transform: translateX(0);
}

.section {
  max-width: var(--width-max);
  margin: 0 auto;
  width: 90%;
  margin-top: 2rem;
}

.section-title {
  text-align: center;
  color: var(--primary-500);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-family: var(--ff-heading);
  font-weight: 600;
  font-size: 1rem;
}

.title-underline {
  background: var(--gray-200);
  width: 7rem;
  height: 0.1rem;
  margin: 0 auto;
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
}

.section-sub-title{
  text-align: center;
  color: var(--color-txt);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-family: var(--ff-heading);
  font-weight: 600;
  font-size: 1.875rem;
  margin-bottom: 1rem;
}

.section-info{
  text-align: center;
  color: var(--gray-500);
}

.section-content {
  width: 100%;
  text-align: center;
}

.highlight-text{
  color: var(--primary-600);
}
/* End Of Section */

/* Start Of Images */
.container {
  width: 50vw;
  max-width: var(--width-max);
}

.img {
  width: 100%;
  display: block; /* Remove the space from the bottom of the container */
  -o-object-fit: cover;
     object-fit: cover;
}
/* End Of Images */

/* Start of Code */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* End of Code */
/* Start of Anchor */
a {
  text-decoration: none;
  text-transform: capitalize;
  color: var(--txtColor);
  letter-spacing: var(--letter-spacing);
  font-size: 1rem;
  transition: var(--);
}
/* End of Anchor */
/* Start Of Buttons */
.btn {
  cursor: pointer;
  color: var(--white);
  background-color: var(--primary-500);
  border: 2px solid transparent;
  border-radius: var(--radius);
  letter-spacing: var(--letter-spacing);
  padding: 0.375rem 0.75rem;
  box-shadow: var(--shadow-1);
  transition: var(--transition-2);
  text-transform: capitalize;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.btn:hover {
  background-color: var(--primary-700);
  box-shadow: var(--shadow-3);
  font-weight: 600;
}

.btn-reverse {
  background-color: var(--primary-700);
  box-shadow: var(--shadow-3);
}

.btn-reverse:hover {
  box-shadow: var(--shadow-1);
  background-color: var(--primary-500);
}

.btn-hollow {
  background-color: transparent;
  border-color: var(--primary-500);
  color: var(--primary-500);
}

.btn-hollow:hover {
  background-color: var(--primary-500);
  color: var(--white);
}

.btn-rhollow {
  background-color: var(--primary-500);
  color: var(--white);
}

.btn-rhollow:hover {
  background-color: transparent;
  border-color: var(--primary-500);
  color: var(--primary-500);
}

.btn-hipster {
  color: var(--primary-500);
  background: var(--primary-200);
}

.btn-hipster:hover {
  color: var(--primary-200);
  background: var(--primary-700);
}

.btn-rounded {
  border-radius: var(--radius-half-circle);
}

.btn-scaled:hover {
  transform: scale(1.05);
}

.btn-block {
  width: 100%;
  /* Remove the line bellow if needed. I makes the element go full width in a grid template */
  grid-column: 1 / -1;
}
/* End Of Buttons */
/* Start of Forms */
.form-items-reverse{
  direction: rtl;
}

.cform {
  margin: 0 auto;
  max-width: var(--width-section);
  width: 90vw;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius: var(--radius);
  border: 2px solid var(--gray-300);
  background-color: var(--color-bg);
  transition: var(--transition-2);
  letter-spacing: var(--letter-spacing);
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border: 2px solid var(--primary-200);
  font-family: inherit;
  letter-spacing: var(--letter-spacing);
}

.form-input:hover,
.form-textarea:hover {
  border: 2px solid var(--primary-200);
}

.form-input:valid,
.form-textarea:valid {
  outline: none;
  border: 2px solid var(--green-mid);
  letter-spacing: var(--letter-spacing);
}

textarea{
  resize: none;
}

.form-row {
  position: relative;
  margin-bottom: 1rem;
  margin-left: 0;
  margin-right: 0;
}

.form-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  transition: var(--transition-2);
  float: left;
}

label{
  margin: 0;
}

.form-switch:hover {
  transform: scale(1.02);
}

.form-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-200);
  transition: var(--transition-2);
}

.slider:hover {
  background-color: var(--gray-50);
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: var(--primary-400);
  /* background-color: var(--gray-400); */
  transition: var(--transition-2);
}

input:checked + .slider {
  background-color: var(--primary-400);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--gray-200);
}

input:checked + .slider:before {
  transform: translateX(26px);
  box-shadow: 0 0 1px var(--primary-400);
}

input:checked + .slider::before {
  background-color: var(--gray-50);
}

.round {
  border-radius: 34px;
}

.condition-label {
  display: inline;
  margin: 0;
}

.condition-label a{
  font-size: 0.9rem!important;
  letter-spacing: var(--letter-spacing);  
}

.condition-label a:hover{
  text-decoration: none;
}

.condition-label .link {
  font-size: 0.7rem;
}

.slider.round:before {
  border-radius: var(--radius-circle);
}

.form-row-condition {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 1rem;
  margin: 0 auto!important;
  margin-top: 1rem!important;
}

::-webkit-input-placeholder {
  font-family: inherit;
  color: var(--gray-400);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  padding-left: 0.25rem;
}

::-moz-placeholder {
  font-family: inherit;
  color: var(--gray-400);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  padding-left: 0.25rem;
}

:-ms-input-placeholder {
  font-family: inherit;
  color: var(--gray-400);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  padding-left: 0.25rem;
}

::-ms-input-placeholder {
  font-family: inherit;
  color: var(--gray-400);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  padding-left: 0.25rem;
}

::placeholder {
  font-family: inherit;
  color: var(--gray-400);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing);
  padding-left: 0.25rem;
}

.form-alert-error {
  color: var(--red-dark);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  transition: var(--transition-2);
}

.form-alert-success {
  color: var(--green-dark);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  transition: var(--transition-2);
}

.form-alert{
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  transition: var(--transition-2);
}

.form-alert-error {
  color: var(--red-dark);
}

.form-alert-success {
  color: var(--green-dark);
}

/* End of Forms */
/* Start of Animations */
.animation {
  width: 2.5rem;
  height: 2.5rem;
  border: 5px solid var(--gray-400);
  border-top-color: var(--primary-500);

  -webkit-animation-name: loading-circle;

          animation-name: loading-circle;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;

  /* animation: loading-circle 0.6s linear infinite; */
  margin: 0!important;
  transition: var(--transition-2);
}


.animation-circle {
  border-radius: var(--radius-circle);
}

.animation-square {
  border-color: var(--primary-500);
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  -webkit-animation-name: loading-square;
          animation-name: loading-square;
}

.show-animation{
  display: flex;
}

.hide-animation{
  display: none;
}

@-webkit-keyframes loading-circle {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loading-circle {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loading-square {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes loading-square {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
/* End of Animations */

.recpatcha-container{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.recpatcha-container-email-verification{
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

/* Start Of Media Queries */
/* Small Phones */
@media screen and (min-width: 320px) {
}
/* Medium Phones */
@media screen and (min-width: 375px) {
}
/* Large Phones */
@media screen and (min-width: 425px) {
}

/* Tablets */
@media screen and (min-width: 768px) {
}

/* Laptops */
@media screen and (min-width: 1024px) {
}

/* Large Laptops */
@media screen and (min-width: 1440px) {
}

/* 4K Screens */
@media screen and (min-width: 2560px) {
}
/* End Of Media Queries */