.study-container {
  height: 100%;
  width: 100%;

  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  gap: 1rem;
  background: url('../../images/geometry2.png') 100% / contain repeat fixed;
  background-size: 12rem;

  grid-template-columns: 1fr;

  position: absolute;
}

.loading-animation {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  /* background: rgba(0,0,0,0.8); */
  /* background: var(--black-mid); */
  background: var(--gray-50);
  /* animation: zoom-in 1s ease-in-out forwards; */
  animation-delay: 0s;
  z-index: 10000;
  transform: scale(1);
  opacity: 1;
}

.loading-animation-general {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.loading-animation.show-animation {
  animation: zoom-in 1s ease-in-out forwards;
  animation-delay: 0s;
  z-index: 10000;
}

.loading-animation.remove-animation {
  animation: zoom-out 1s ease-in-out forwards;
  animation-delay: 0s;
  z-index: 10000;
}

/* .loading-animation.first-animation{
    transform: scale(1);
    z-index: 10000;
    opacity: 1;
} */

@keyframes zoom-out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.study-container-main {
  width: 75vw;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.reverse-elements {
  flex-direction: row-reverse;
}

.study-arrows {
  text-align: center;
  font-size: 1.25rem;
  color: var(--gray-700);
  transition: var(--transition-2);
  cursor: pointer;
}

.study-arrows:hover {
  color: var(--primary-500);
}

.study-arrows-container {
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.open-progress-btn {
  height: 3rem;
  width: 100%;
}

.open-progress-btn i {
  cursor: pointer;
  font-size: 2rem;
  transition: var(--transition-2);
  right: 3%;
  top: 3%;
  transform: translate(-3%, -3%);
  position: absolute;
}

.open-progress-btn i:hover {
  color: var(--primary-500);
}

.study-word-title {
  background-color: var(--gray-100);
  border: 2px solid var(--gray-200);
  width: 90%;
  margin: 0 auto;
  text-align: center;
  letter-spacing: var(--letter-spacing);
  font-weight: 600;
  font-size: 1.5rem;
  word-wrap: break-word;
  padding: 1.5rem;
}

.fa-arrow-left {
  align-self: end;
}

.fa-arrow-right {
  align-self: start;
}

.question-option-list {
  display: flex;
  justify-items: center;
  align-items: center;
  /* gap: 1rem; */
  list-style-type: none;
  flex-direction: column;
  margin: 1rem auto;

  width: 100%;
  max-width: 40rem;
}

.question-option {
  padding: 1rem;
  letter-spacing: var(--letter-spacing);
  color: var(--gray-900);
  width: 90%;
  text-align: center;
  cursor: pointer;
  transition: var(--transition-2);
  word-wrap: break-word;
  user-select: none;
}

.question-option.selected {
  color: var(--gray-50);
  background-color: var(--primary-500);
}

.question-option.selected:hover {
  background-color: var(--primary-400);
  color: var(--gray-50);
}

.question-option:hover {
  background-color: var(--primary-100);
  color: var(--gray-900);
}

.question-option.wrong-answer {
  background-color: var(--red-mid);
  color: var(--gray-50);
}

.question-option.wrong-answer:hover {
  background-color: var(--red-light) !important;
  color: var(--gray-900);
}

.question-option.good-answer {
  background-color: var(--green-dark);
  color: var(--gray-50);
}

.question-option.good-answer:hover {
  background-color: var(--green-mid) !important;
  color: var(--gray-900);
}

.question-option-middle {
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid var(--gray-300);
}

.btn-next-question {
  margin-top: 1rem;
}

.btn-next-question:hover {
  background-color: transparent !important;
  color: var(--primary-500);
  border: 2px solid var(--primary-500);
}

.btn-next-question:focus {
  /* color: var(--gray-50)!important; */
}

.found-a-mistake {
  font-size: 0.75rem;
  cursor: pointer;
  transition: var(--transition-2);
}

.found-a-mistake:hover {
  color: var(--primary-500) !important;
}

.classification-list {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

.btn-classification-emoji {
  font-size: 3rem;
  transition: var(--transition-2);
  cursor: pointer;
}

.btn-classification-emoji.disabled-classification {
  pointer-events: none !important;
}

.btn-classification-emoji.selected-classification {
  transform: scale(1.1);
}

.btn-classification-emoji.grayed-out-classification {
  opacity: 0.5;
}

.fa-face-frown {
  color: var(--red-mid);
  transition: var(--transition-2);
}

.fa-face-meh {
  color: var(--yellow);
  transition: var(--transition-2);
}

.fa-face-smile {
  color: var(--green-mid);
  transition: var(--transition-2);
}
.fa-face-frown:hover {
  color: var(--red-dark);
}

.fa-face-meh:hover {
  color: var(--yellow-dark);
}

.fa-face-smile:hover {
  color: var(--green-dark);
}

.scale-animation {
  animation: scale-animation 1s infinite;
}

.p-continue {
  letter-spacing: var(--letter-spacing);
  margin-top: 1rem;
  transition: var(--transition-2);
  transform: translateX(-10vw);
  opacity: 0;
}

.p-continue.visible {
  transform: translateX(0);
  opacity: 1;
}

.associations-parent {
  transition: var(--transition-2);
  margin-top: 0.5rem;
}

/* GRAY OUT */
.association-header.gray-out {
  opacity: 0.2;
  color: var(--gray-100);
  pointer-events: none;
}

.association-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: var(--transition-2);
}

.association-header i {
  font-size: 1.5rem;
  color: var(--red-mid);
  transition: var(--transition-2);
  cursor: pointer;
  opacity: 0;
}

.association-header span {
  font-size: 1rem;
  color: var(--gray-900);
  transition: var(--transition-2);
  cursor: pointer;
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
}

.association-header:hover span {
  color: var(--red-mid);
}

.association-header i {
  opacity: 1;
}

.association-text p {
  letter-spacing: var(--letter-spacing);
  color: var(--gray-900);
  font-size: 1rem;
  margin: 0 !important;
  max-width: 20rem;
  width: inherit;
}

.association-text {
  /* border: 2px solid var(--gray-200); */
  width: 100%;
  opacity: 0;
  transform: var(--transition-2);
  max-width: 18rem;
  word-wrap: break-word;
  text-align: center;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
}

.association-text.visible {
  opacity: 1;
}

.edit-association,
.confirm-association,
.exit-association {
  color: var(--gray-900);
  transition: var(--transition-2);
  cursor: pointer;
}

.edit-association:hover {
  color: var(--primary-400);
}

.confirm-association:hover {
  color: var(--green-mid);
}

.exit-association:hover {
  color: var(--red-mid);
}

#loading-spinner-login5 {
  display: flex;
  position: absolute;
  z-index: 4000;
  left: 50%;
  top: 3%;
  transform: translate(-50%, -3%);
}

#associationEditInput {
  background: transparent;
  border: 2px solid var(--gray-900);
  outline: none;
  box-shadow: none;
  border-radius: var(--radius);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}

.association-edit {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
}

@media screen and (min-width: 425px) {
  .study-arrows {
    font-size: 1.5rem;
  }

  .study-container-main {
    justify-content: center;
  }

  .association-text {
    max-width: 20rem;
  }
}

@media screen and (min-width: 768px) {
  .study-arrows {
    font-size: 2rem;
  }

  .association-text {
    max-width: 30rem;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .open-progress-btn {
    visibility: hidden;
  }

  .association-text {
    max-width: 35rem;
  }
}

@keyframes scale-animation {
  0% {
    transform: scale(1);
    color: var(--primary-500);
  }
  50% {
    transform: scale(1.2);
    color: var(--gray-700);
  }
  100% {
    transform: scale(1);
    color: var(--primary-500);
  }
}
