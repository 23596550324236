.alert-overlay{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    margin: 1rem auto;
    /* left: 50%; */
    transform: translate(-50%, 0);
    width: 95vw;
    max-width: 30rem;
}

.alert-modal{
    width: 100%;
    max-width: var(--width-section);
    background-color: var(--black-mid);
    border: 2px solid var(--gray-900);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    padding: 1rem;
    transition: var(--transition-2);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 0px 30px rgba(0, 0, 0, 0.1);

    position: relative;

    border-radius: var(--radius);
    
}

.alert-modal .alert-modal-icon-container{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--gray-50);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid var(--gray-50);
    outline-offset: 10px;
    margin: 1rem 0 1.5rem 0;
}

.alert-modal .alert-modal-icon-container i{
    color: var(--gray-900);
    font-size: 1.5rem;
}

.alert-modal .fa-xmark{
    color: var(--gray-900);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 1.75rem;
    cursor: pointer;
    transition: var(--transition-2);
}

.alert-modal .fa-xmark:hover{
    color: var(--red-mid);
}

.alert-modal-type{
    font-size: 1.25rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    font-family: var(--ff-heading);
    font-weight: 600;
    color: var(--primary-600);
}

.alert-modal-type-warning, .alert-modal-type-warning-exam{
    color: var(--yellow-mid);
}

.alert-modal-type-error{
    color: var(--red-mid);
}

.alert-modal-type-success{
    color: var(--green-dark);
}

.alert-modal-title{
    color: var(--primary-500);
    font-family: var(--ff-heading);
    text-transform: capitalize;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: var(--letter-spacing);
}

.alert-modal-description-regular{
    color: var(--gray-50)!important;
    font-family: var(--ff-body);
    text-transform: capitalize;
    font-size: 1rem!important;
    font-weight: 600!important;
    letter-spacing: var(--letter-spacing)!important;
}

.fade-in-bottom-alert { 
    opacity: 0;
    transform: translate(-50%, -10vh);
    transition: all 1s;
    z-index: -2000;
}

.fade-in-bottom-alert.visible {
    opacity: 1;
    transform: translate(-50%, 0);
    z-index: 2000;
}

#closeUnitModal{
    color: var(--gray-900)!important;
}

#closeUnitModal:hover{
    color: var(--red-mid)!important;
}

#CustomUnitModal{
    background-color: var(--gray-100);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-70%, -50%);

    min-width: 15rem;   
    width: 90%;
    max-width: 30rem;

    min-height: 20rem;
    max-height: 30rem;
    height: auto;

    border-color: var(--primary-500);

    transition: var(--transition-2);

    opacity: 0;
    transform: translateY(-10vh);
    transition: var(--transition-2);
}

#CustomUnitModal.visible{
    opacity: 1!important;
    transform: translate(-50%, -50%);
    transition: var(--transition-2);
    z-index: 900;
}

.custom-main{
    height: 100%;
    width: 100%;
    text-align: center;

    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;
}

.new-unit-words{
    overflow-y: auto;
    height: 100%;
    width: 100%;
    background-color: var(--gray-50);
    border-radius: var(--radius);
    border: 2px solid var(--gray-300);
    max-height: 10rem;
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem;
    gap: 10px;
}

.new-unit-words::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

.new-word{
    background-color: var(--gray-200);
    padding: 2px 10px;
    border-radius: var(--radius-half-circle);
    text-align: center;
    margin: 0;
    cursor: pointer;
    flex: 0 0 auto; /* Prevent items from growing or shrinking */
    height: 100%;
}

.new-word:hover .delete-word{
 color: var(--red-mid);
}
  
.new-unit-words .delete-word{
    left: 0!important;
    right: 0!important;
    bottom: 0!important;
    position: relative!important;
    font-size: 1rem!important;
    margin-left: 5px!important;
    top: 1px!important;
}

.new-unit-words::-webkit-scrollbar-thumb {
    background-color: var(--primary-300);
    /* border-radius: 10px; */
  }
  
.new-unit-words::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* border-radius: 10px; */
  }

.unit-option{
    color: var(--gray-900);
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid var(--gray-200);
    padding: 1rem;
    cursor: pointer;
    transition: var(--transition-2);
}

.unit-option a {
    font-weight: 600!important;
    font-size: 1.25rem;
}

.unit-option:hover{
    border-color: var(--primary-500);
}

.unit-option:hover a{
    color: var(--primary-500)!important;
}

.unit-option-explanations{
    opacity: 0;
    transition: var(--transition-2);
    white-space: wrap;
    position: absolute;
    background-color: var(--gray-100);
    border: 2px solid var(--primary-600);
    border-radius: var(--radius);
    top: 105%;
    height: auto;
    min-height: 2rem;
    transform: translateY(-5vh);
    padding: 0 1rem;
    min-width: 18rem!important;
}

.unit-option-explanations p {
    margin: 0;
}

.unit-option-explanations.visible{
    opacity: 1;
    transform: translateY(0);
    transition: var(--transition-2);
}

.unit-option-explanation{
    display: none;
    color: var(--gray-900);
}

.unit-option-explanation.visible{
    display: block;
}

#CustomUnitModal .unit-option-explain{
    color: var(--gray-400);
    letter-spacing: var(--letter-spacing);
}

.unit-title{
    text-align: center;
    color: var(--primary-500);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-family: var(--ff-heading);
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
}

.new-unit-title label{
    text-align: center;
    color: var(--gray-900);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-family: var(--ff-heading);
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
}

.new-unit-title-input input {
    border: 2px solid var(--gray-300);
}

#new-unit-checkbox{
    margin-top: 0!important;
    margin-bottom: 1rem!important;
}

.new-unit-btn{
    margin-top: 1rem;
    font-weight: 600!important;
    border: 2px solid transparent;
}

.new-unit-title-input:hover input{
    border-color: var(--primary-400)!important;
}

.new-unit-btn:hover{
    background-color: transparent!important;
    color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
}

.add-word-menu-btn{
    transition: var(--transition-2);
    border: 2px solid var(--gray-900);
    border-radius: 50%;
    padding: 3px;
}

.add-word-menu-btn:hover{
    color: var(--primary-500);
    border-color: var(--primary-500);
}

.new-word-menu-btn-add{
    background-color: transparent;
    padding: 2px 10px;
    border-radius: var(--radius-half-circle);
    text-align: center;
    margin: 0;
    cursor: pointer;
    flex: 0 0 auto; /* Prevent items from growing or shrinking */
    height: 100%;
}

.new-word-menu-btn-add:hover .add-word-menu-btn{
    color: var(--primary-500);
    border-color: var(--primary-500);
}

@media screen and (min-width: 425px){
    .unit-option-explanations{
        min-width: 25rem!important;
    }
}

@media screen and (min-width: 560px){
    .unit-option-explanations{
        min-width: 33rem!important;
    }
}

@media screen and (min-width: 768px){
    .unit-option-explanations{
        min-width: 46rem!important;
    }
}

@media screen and (min-width: 1024px){
    .unit-option-explanations{
        min-width: 62rem!important;
    }
}

@media screen and (min-width: 1100px){
    .unit-option-explanations{
        white-space: nowrap;
    }
}