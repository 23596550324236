.main-document {
  padding-top: 6rem;
  width: 95vw;
  margin: 0 auto;
  /* padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.document-title {
  margin: 0 auto;
  margin-bottom: 1.5rem;
  font-size: 2rem !important;
}

.update-date {
  color: var(--primary-400) !important;
}

.document-contents {
  text-align: center;
  margin: 0 !important;
  color: var(--gray-900);
  max-width: var(--width-max);
}

.section-security-contact {
  /* margin: 2rem 0 0 0!important; */
  /* margin: 0!important; */
  width: auto !important;
}

.document-contents h3 {
  color: var(--primary-400);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-family: var(--ff-heading);
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.document-contents p {
  color: var(--gray-900);
  letter-spacing: 0.05rem;
  font-family: var(--ff-body);
  font-weight: 600;
  font-size: 1rem;
  max-width: 50rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.document-contents ul {
  color: var(--gray-900);
  letter-spacing: 0.05rem;
  font-family: var(--ff-body);
  font-weight: 600;
  font-size: 1rem;
  list-style-type: none;
}

.document-contents li {
  margin-bottom: 1rem;
  text-decoration: underline;
}

@media screen and (min-width: 613px) {
  .section-security-contact {
    /* margin: 2rem auto!important; */
  }
}
