#main {
  min-height: calc(100vh - 5rem);
  height: 100%;
}

#sectionTitle {
  margin-top: 1rem;
  font-size: 2rem;
}

#sectionBanner {
  text-align: center;
  color: var(--gray-500);
  font-weight: 600;
  letter-spacing: var(--letter-spacing);
}

/* GRAY OUT */
.tutorial-container.active.gray-out {
  opacity: 0.2 !important;
  color: var(--gray-100) !important;
  pointer-events: none !important;
}

.tutorial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  border: 2px solid var(--gray-200);
  background-color: var(--gray-100);

  width: 90%;
  max-width: 625px;
  padding: 1rem;

  margin: 0 auto;
  margin-top: 1rem;
}

.tutorial-container.last {
  margin-bottom: 1rem;
}

.tutorial-container.inverted .btn-tutorial {
  color: var(--gray-50) !important;
  border: 2px solid var(--gray-50) !important;
  background-color: var(--black-mid) !important;
}

.tutorial-container.inverted .btn-tutorial:hover {
  color: var(--black-mid) !important;
  border: 2px solid var(--black-mid) !important;
  background-color: transparent !important;
}

.tutorial-icon {
  border: 2px solid var(--primary-400);
  border-radius: 50%;

  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;
}

.tutorial-icon i {
  color: var(--primary-400);
  font-size: 1.25rem;
}

.tutorial-banner {
  text-align: center;
  letter-spacing: var(--letter-spacing);
  line-height: 1.75;
}

.tutorial-banner h1 span {
  color: var(--primary-400) !important;
  text-transform: capitalize;
}

.tutorial-banner p {
  font-weight: 400;
  color: var(--gray-800);
}

.btn-tutorial {
  margin-bottom: 0 !important;
}

.tutorial-banner .btn-tutorial:hover {
  color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
  background-color: transparent !important;
}

.tutorial-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.tutorial-container.active {
  opacity: 1;
}

@media screen and (min-width: 700px) {
  .tutorial-container {
    flex-direction: row;
    height: 15rem;
    padding: 0;
    background-color: transparent;
    border: none;
    margin-bottom: 2rem;
  }

  .tutorial-container.inverted {
    flex-direction: row-reverse;
  }

  .tutorial-banner {
    padding: 0 1rem;
    text-align: left;
  }

  .tutorial-container.inverted .tutorial-banner {
    text-align: right;
  }

  .tutorial-icon {
    border-radius: var(--radius);
    width: 15rem;
    height: 15rem;
    margin-bottom: 0;
    margin-top: 0;
    flex: none;
  }

  .tutorial-icon i {
    font-size: 3rem;
  }
}
