#examStartScreen{
    width: 100vw;
    z-index: 5000;
    background-color: var(--gray-50);
    opacity: 1;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

#startScreenPanel{
    width: 20rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#startScreenPanel h1{
    font-size: 2.5rem;
    color: var(--primary-500);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
}

#startScreenPanel h3{
    font-size: 1.5rem;
    color: var(--primary-400);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

#startScreenPanel p{
    font-size: 1.25rem;
    color: var(--gray-900);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
    margin: 1.5rem 0 0.5rem 0;
}

#startScreenPanel ul{
    list-style: none;
    font-size: 1rem;
    color: var(--gray-900);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing);
    font-weight: 500;
    font-family: var(--ff-body);
}

#startScreenPanel ul li span{
    font-weight: 600;
}

.btn-start-screen:hover{
    background-color: transparent!important;
    color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
}
#startExamBtn.gray-out-btn{
    cursor: not-allowed!important;
    background: var(--primary-700)!important;
    color: var(--gray-500)!important;
}

#startExamBtn{
    margin-bottom: 0!important;
}

#startExamBtn.gray-out-btn:hover{
    cursor: not-allowed!important;
    background: var(--primary-800)!important;
    color: var(--gray-500)!important;
}



@media screen and (min-width: 425px){
    #startScreenPanel {
        width: 25rem;
    }

    #startScreenPanel h3{
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 425px){
    #startScreenPanel {
        width: 35rem;
    }
}

@media screen and (min-width: 768px){

}
