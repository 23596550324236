.search-bar-input{
    border: 2px solid var(--gray-400);
    border-radius: var(--radius);
    padding: 0 1rem;
    letter-spacing: var(--letter-spacing);
    transition: var(--transition-2);
    text-align: center;
}   

.search-bar-input:hover{
    border-color: var(--gray-500);
}   

.search-bar-input::placeholder{
    color: var(--gray-300);
}   

.search-bar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 10px;
    position: relative
}

.search-bar-container i{
    position: absolute;
    right: 90%;
    cursor:text;
    color: var(--gray-300);
}

.search-bar-container i.reverse-side{
    left: 90%;
}