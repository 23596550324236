#ExamModalOverlay{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exam-modal-buttons-div{
    display: flex;
    margin: 0!important;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.btn-learn-the-unknown:hover{
    background-color: transparent!important;
    color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
}

.btn-retry-the-exam{
    background-color: transparent!important;
    color: var(--black-mid)!important;
    border-color: var(--black-mid)!important;
}

.btn-retry-the-exam:hover{
    background-color: var(--black-mid)!important;
    color: var(--gray-50)!important;
    border-color: var(--gray-50)!important;
}

#ExamModalOverlay #endScreenPanel{
    width: 85%;
    height: 85%;
    background-color: var(--gray-50);
    border: 2px solid var(--gray-200);
    margin: 0 auto;
    overflow-y: auto;
}

#ExamModalOverlay #endScreenPanel::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

#ExamModalOverlay #endScreenPanel::-webkit-scrollbar-thumb {
    background-color: var(--primary-400);
    /* border-radius: 10px; */
}

#ExamModalOverlay #endScreenPanel::-webkit-scrollbar-track {
    background-color: transparent
    /* border-radius: 10px; */
}

#ExamModalOverlay #endScreenPanel #closeModal{
    color: var(--gray-900)!important;
    font-size: 2.5rem;
    margin: 0!important;
    position: absolute;
    left: 8%;
    cursor: pointer;
    transition: var(--transition-2);
}

#ExamModalOverlay #endScreenPanel #closeModal:hover{
    color: var(--red-mid)!important;
}

#ExamModalOverlay .questions-status{
    margin: 0 auto;
}

@media screen and (min-width: 768px){
    #ExamModalOverlay  .questions-status {
        width: inherit;
        min-width: inherit;
    }

    .exam-modal-buttons-div{
        flex-direction: row;
    }
}

@media screen and (min-width: 1024px) {
    #ExamModalOverlay #endScreenPanel #closeModal{
        left: 8%;
    }
}

@media screen and (min-width: 1440px) {
    #ExamModalOverlay #endScreenPanel #closeModal{
        left: 8%;
    }
}