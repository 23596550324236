.flippable-card-container-study {
    height: 10rem;
    width: 18rem;
    perspective: 1000px;
    min-height: 10rem;
    min-width: 18rem;
    margin-top: 2rem;
}

@media screen and (min-width:375px) {
    .flippable-card-container-study {
        height: 12rem;
        width: 20rem;
    }
}

@media screen and (min-width:425px) {
    .flippable-card-container-study {
        height: 14rem;
        width: 23rem;
    }
}

@media screen and (min-width:1024px) {
    .flippable-card-container-study {
        height: 15rem;
        width: 25rem;
    }
}