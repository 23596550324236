.progress-bar-study{
    height: 80vh;
    width: 25vw;
    background-color: var(--gray-200);
    border-right: 2px solid var(--gray-300);
    position: fixed;
    border-radius: 3rem;
    transform: translateX(-100%);
    transition: var(--transition-2);
    max-width: 4rem;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 2000;  
    margin-top: 3rem;
}

.progress-bar-study::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
.progress-bar-study::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
    border-radius: 10px;
}

.progress-bar-study::-webkit-scrollbar-track {
    background-color: transparent;
}

.progress-bar-study.visible{
    transform: translateX(0);
}

.progress-bar-study::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 3rem; 
}

.progress-bar-study::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 3rem;
}

.progress-bar-study ul{
    transition: var(--transition-2);
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 8px;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}

.study-question-2{
    background-color: var(--red-mid)!important;
    border-color: var(--red-mid)!important;
}

.study-question-3{
    background-color: var(--gray-300)!important;
    border-color: var(--gray-300)!important;
}

.study-question-0{
    background-color: var(--green-mid)!important;
    border-color: var(--green-mid)!important;
}

.study-question-1{
    background-color: var(--yellow)!important;
    border-color: var(--yellow)!important;
}

.progress-bar-item-study{
    width: 40px;
    height: 40px;
    background-color: var(--gray-300);
    border-radius: 50%;
    border: 2px solid var(--gray-300);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-900);
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
    transition: var(--transition-2);
}

.progress-bar-item-study:hover{
    background-color: var(--primary-300);
    border-color: var(--primary-300);
}

.current-study-question{
    background-color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
    color: var(--gray-50)!important;
}



.done-question{
    background-color: var(--green-mid);
    border-color: var(--green-mid);
    color: var(--gray-900);
}


.disable-progress-bar{
    pointer-events: none;
}

.study-finish-button{
    font-size: 3rem;
    transition: var(--transition-2);
    cursor: pointer;
    color: var(--gray-900);
}

.study-finish-button:hover{
    color: var(--primary-600);
}

.study-next-button{
    font-size: 3rem;
    transition: var(--transition-2);
    cursor: pointer;
    color: var(--gray-900);
}

.study-next-button:hover{
    color: var(--primary-600);
}

.study-prev-button{
    font-size: 3rem;
    transition: var(--transition-2);
    color: var(--gray-900);
}

.not-allowed-class{
    cursor: not-allowed;
}

.allowed-class{
    cursor: pointer;
}

.study-prev-button:hover{
    color: var(--primary-600);
}

.study-prev-not-button{
    cursor: not-allowed !important;
    font-size: 3rem;
    transition: var(--transition-2);
    color: var(--gray-300);
}

.study-next-not-button{
    cursor: not-allowed !important;
    font-size: 3rem;
    transition: var(--transition-2);
    color: var(--gray-300);
}

.done-btn{
    padding-bottom: 0.5rem;
}

.arrow-down-btn{
    /* TODO: maybe 2.5 to fix phones*/
    padding-bottom: 0.5rem;
}

.arrow-up-btn{
    padding-top: 0.5rem;
}

@media screen and (min-width: 1024px) {
    .progress-bar-study{
        transform: translateX(0);
    }
}
