.fade-in-top.visible-cookies {
    opacity: 1;
    transform: translateY(0);
    z-index: 100;
  }
  

.cookie-agreement{
    width: 80vw;
    max-width: 350px;
    height: 24rem;
    background-color: var(--gray-100);
    border-top: 2px solid var(--gray-200);
    border-left: 2px solid var(--gray-200);
    border-right: 2px solid var(--gray-200);
    box-shadow: var(--shadow-3);
    position: fixed;
    z-index: -5;
    bottom: 0;
    right: 10vw;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    padding: 1rem;
    text-align: center!important;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cookie-agreement h1{
    text-align: center;
}

.cookie-agreement p{
    text-align: center;
    color: var(--gray-500);
}

.hebrew-right{
    text-align: right!important;
}

.cookies-buttons-allow, .cookies-buttons-reject, .privacy-policy-button, .privacy-policy-button{
    margin: 0!important;
    margin-bottom: 0.5rem!important;
}

.cookies-buttons-allow:hover, .cookies-buttons-reject:hover{
    background-color: transparent!important;
    border: 2px solid var(--primary-500);
    font-weight: 600;
}

.policy-buttons{
    margin: 1rem auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.25rem 0;
}

.policy-buttons a{
    background-color: transparent;
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing);
    font-family: var(--ff-heading);
    font-weight: 600;
    font-size: 1rem;
    box-shadow: none;
    text-shadow: none;
    transition: var(--transition-1)!important;
    padding: 0;
    margin: 0!important;
    color: var(--gray-900);
    height: 100%!important;
}

.policy-buttons a:hover{
    background-color: transparent;
    text-transform: capitalize;
    font-weight: 600;
    box-shadow: none;
    transition: var(--transition-1)!important;
}

.cookies-buttons-reject{
    background-color: transparent!important;
    border: 2px solid var(--gray-500)!important;
    color: var(--gray-900)!important;
}

.cookies-policy-button{
    margin: 0!important;
}