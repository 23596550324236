.environment {
  width: 100%;
  background: url('../../images/geometry2.png') 100% / contain repeat fixed;
  background-size: 12rem;
  min-height: calc(100vh - 7.5rem);
  height: 100%;
  margin-top: 5rem;
}

#environmentTitle {
  font-size: 2rem;
  color: var(--primary-500);
  text-align: center;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  font-weight: 600;
  font-family: var(--ff-heading);
  letter-spacing: var(--letter-spacing);
}

.gray-out-modele-bands {
  background-color: var(--gray-100);
  border-color: var(--gray-50);
}

.gray-out-modele-bands p {
  color: var(--primary-200) !important;
}

.selected-modele-band {
  background-color: var(--primary-500) !important;
  border-color: var(--primary-500) !important;
}

.selected-modele-band:hover {
  border-color: var(--primary-500) !important;
}

.selected-modele-band:hover p {
  color: var(--primary-500) !important;
}

.selected-modele-band p {
  color: var(--gray-50) !important;
}

.environment-main {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.environment-topics {
  width: 100%;
  height: 7rem;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center !important;
  gap: 0 1rem;
  white-space: nowrap;
  /* padding-left: 200px;
    padding-right: 200px; */
}

.environment-carousel {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 45rem;
  margin: 0 auto;
  width: 90%;
}

.environment-carousel::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.environment-carousel::-webkit-scrollbar-thumb {
  background-color: var(--gray-100);
  /* border-radius: 10px; */
}

.environment-carousel::-webkit-scrollbar-track {
  background-color: transparent;
  /* border-radius: 10px; */
}

.topic-big-logo {
  width: 300px !important;
  height: 300px;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 2rem;

  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: translateY(10vh);
  transition: all 1s;
}

.show-topic-big-logo {
  opacity: 1;
  transform: translateY(0);
}

.hide-topic-big-logo {
  display: none !important;
}

.topic-big-logo img {
  width: 100%;
  min-width: 100px !important;
  max-width: 300px;
  height: 100%;
}

.environment-topic {
  width: 80px !important;
  height: 80px;
  background-color: var(--gray-200);
  border: 2px solid var(--gray-300);
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transition-2);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
}

/* GRAY OUT */
.environment-topic.gray-out {
  opacity: 0.2;
  background-color: var(--gray-700);
  color: var(--gray-100);
  border: 1px solid var(--gray-800);

  pointer-events: none;
}

.unit-option.gray-out {
  opacity: 0.2;
  color: var(--gray-900);
  pointer-events: none;
}

.environment-topic:hover {
  transform: scale(1.05);
}

.environment-topic:hover p,
.environment-topic:hover i {
  color: var(--primary-500);
}

.environment-topic p,
.environment-topic i {
  color: var(--gray-900);
  margin: 0;
  letter-spacing: var(--letter-spacing);
  font-weight: 600;
  font-size: 0.55rem;
  text-transform: capitalize;
  cursor: pointer;
  transition: var(--transition-2);
}

.environment-topic-active {
  transform: scale(1.05);
}

.environment-topic-active-p {
  color: var(--primary-500) !important;
}

.environment-topic-parent {
  cursor: pointer;
  transition: var(--transition-2);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.text-fade-in-bottom {
  opacity: 0;
  transform: translateY(-10vh);
  transition: all 1s;
}

.text-fade-in-bottom.visible {
  opacity: 1;
  transform: translateY(0);
}

.p-fade-in-right {
  opacity: 0;
  transform: translateX(-10vh);
  transition: all 1s;
}

.p-fade-in-right.visible {
  opacity: 1;
  transform: translateX(0);
}

.environment-topics-units {
  display: none;
  width: 90%;
  margin: 2rem auto;
  margin-top: 1rem;
}
.environment-topics-units.visible {
  display: block;
}

.environment-topics-units-title {
  font-size: 2rem;
  color: var(--primary-500);
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: 600;
  font-family: var(--ff-heading);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
}

.units-list {
  display: grid;
  width: 100%;
  height: 100%;
  background-color: transparent;
  margin: 0 auto;
  border-radius: var(--radius);
  transition: var(--transition-2);

  grid-template-columns: 1fr;

  max-width: 45rem;
  gap: 1rem;
  margin-bottom: 2rem;
}

.model-e-cores-list {
  opacity: 0;
  transform: translateY(-10vh);
  transition: all 1s;
  border-radius: var(--radius) !important;
  margin: 0 auto;
  margin-top: 1rem;
  max-width: 45rem;
  margin-bottom: 2rem;
  position: absolute;
  justify-self: center;
  transition: all 1s;
}

.model-e-cores-list.visible {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s;
  position: relative;
}

.environment-unit {
  /* width: 50px;
    height: 50px; */
  border: 2px solid var(--gray-300) !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: var(--transition-2);
  background-color: var(--gray-100);
  border-radius: var(--radius-half-circle);
  flex-direction: column;
}

.unit-content-body h1 {
  color: var(--gray-600);
  letter-spacing: 0.05rem;
  font-family: var(--ff-body);
  font-weight: 600;
  margin: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.environment-unit p {
  margin: 0;
  padding: 0.5rem 0;

  font-size: 1rem;
  color: var(--primary-500);
  text-align: center;
  font-weight: 600;
  font-family: var(--ff-body);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  transition: var(--transition-2);

  cursor: pointer;
}

.environment-unit:hover {
  background-color: var(--gray-200) !important;
}

.environment-unit-hover-effect {
  background-color: var(--gray-200);
}

.environment-unit-selected-effect {
  border-color: var(--primary-500) !important;
}

.environment-unit-gray-out-effect {
  background-color: var(--gray-100);
  border-color: var(--gray-50);
}

.environment-unit-p-gray-out-effect {
  color: var(--primary-200) !important;
}

.reverse-grid {
  direction: rtl;
}

.train-bagrut-exams-btn {
  margin: 1rem auto;
  font-weight: 600 !important;
  white-space: normal;
  overflow-wrap: break-word;
  width: 90%;
  max-width: 35rem;
  display: block !important;
  align-items: center;
  justify-content: center;
  transition: var(--transition-2) !important;
  margin-bottom: 2rem;
}

.train-bagrut-exams-btn:hover {
  background-color: transparent !important;
  border: 2px solid var(--primary-500);
  color: var(--primary-500);
}

.environment-quote {
  color: var(--gray-500);
  font-weight: 600;
  font-style: italic;
  letter-spacing: var(--letter-spacing);
  margin: 0 auto;
  text-align: center;
  max-width: 18rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.environment-quote span,
.environment-quote .fa-asterisk {
  color: var(--primary-500);
  font-weight: 600;
  font-style: italic;
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
/* 
#unit-1{
    background-color: var(--gray-100)!important;
    border: 2px solid var(--gray-200)!important;
    color: var(--primary-500)!important;
    font-weight: 600!important;
} */

#addUnitBtn {
  background-color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
}

#addUnitBtn:hover {
  background-color: transparent !important;
}

#addUnitBtn:hover p {
  color: var(--primary-500) !important;
}

#addUnitBtn p {
  font-size: 1.15rem;
  color: var(--gray-50) !important;
  font-family: var(--ff-body);
}

.model-e-unit-options-list {
  list-style-type: none;
  height: 100%;
  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.model-e-unit-option {
  color: var(--gray-900);
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid var(--gray-200);
  padding: 1rem;
  cursor: pointer;
  transition: var(--transition-2);
}

.cores-unit {
  border-radius: var(--radius);
}

.model-e-bandunits {
  opacity: 0;
  transform: translateX(-10vw);
  transition: var(--transition-2);
}

.btn-upgrade-to-premium {
  margin-top: 1rem;
  color: var(--gray-50) !important;
}

.environment-topic-2 {
  z-index: 2000;
}

.btn-upgrade-to-premium:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
}

#upgradeToPremiumHome {
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: inherit;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.model-e-bandunits.visible {
  opacity: 1;
  transform: translateX(0);
  transition: var(--transition-2);
}

#unitsList .finished-units {
  background: var(--primary-500) !important;
}

#unitsList .finished-units:hover {
  background: var(--primary-600) !important;
}

#unitsList .finished-units p {
  color: var(--gray-50) !important;
}

@media screen and (min-width: 375px) {
  .environment-quote {
    max-width: 20rem;
  }

  .environment-topics {
    height: 8rem;
  }

  .environment-topic {
    width: 100px !important;
    height: 100px;
  }

  .environment-topic p,
  .environment-topic i {
    font-size: 0.65rem;
  }
}

@media screen and (min-width: 425px) {
  .environment-quote {
    max-width: 22rem;
  }

  .environment-topics {
    height: 9rem;
  }

  .environment-topic {
    width: 110px !important;
    height: 110px;
  }

  .environment-topic p,
  .environment-topic i {
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 580px) {
  .units-list {
    grid-template-columns: 1fr 1fr;
  }

  .model-e-cores-list {
    grid-template-columns: 1fr 1fr;
  }

  .environment-quote {
    max-width: 30rem;
  }

  .environment-topics {
    height: 10rem;
  }

  .environment-topic {
    width: 140px !important;
    height: 140px;
  }

  .environment-topic p,
  .environment-topic i {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 616px) {
  .environment-topics {
    height: 11rem;
  }

  .environment-topic {
    width: 160px !important;
    height: 160px;
  }

  .environment-topic p,
  .environment-topic i {
    font-size: 1rem;
  }
}

@media screen and (min-width: 800px) {
  .environment-topics {
    height: 13rem;
  }

  .environment-topic {
    width: 190px !important;
    height: 190px;
  }

  .environment-topic p,
  .environment-topic i {
    font-size: 1.25rem;
  }

  .units-list {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .model-e-cores-list {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .units-list-cores {
    grid-template-columns: 1fr 1fr;
  }

  .environment-quote {
    max-width: 40rem;
  }
}

@media screen and (min-width: 1024px) {
  .environment-quote {
    max-width: 60rem;
  }
}
