.features-content{
    margin-top: 2rem;
    display: grid;
    grid-template-rows: repeat(6, 1fr);
}

.features-content-feature{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.features-content-icon{
    color: var(--primary-500);
    border: 1px solid var(--gray-300);
    border-radius: 50%;
    text-align: center;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    margin: 0 auto;
    transition: var(--transition-2);
}


.features-content-icon i{
    /* height: 1.5rem;
    width: 1.5rem; */
    font-size: 1.5rem;
    transition: var(--transition-2);
}

.features-content-feature:hover .features-content-icon{
    transform: scale(1.2);
    background-color: rgba(0, 0, 0, 0.06);
}


.features-content-banner{
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.features-content-title{
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--ff-heading);
    text-transform: capitalize;
    line-height: 1.25;
    letter-spacing: var(--letter-spacing);
}

.features-content-info{
    text-align: center;
    color: var(--gray-500);
    margin-top: 0.5rem;
    max-width: 15rem;
}


/* Tablets */
@media screen and (min-width: 768px) {
    .features-content{
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(3, 33.333%);
    }

    .features-content-feature{
        justify-content: center;
        align-items: start;
        flex-direction: row;
    }

    .features-content-icon{
        align-self: center;
    }
}

/* Laptops */
@media screen and (min-width: 1024px) {
    .features-content{
        grid-template-columns: repeat(3, 33.333%);
        grid-template-rows: repeat(2, 50%);
    }

    .features-content-feature{
        justify-content: center;
        align-items: start;
        flex-direction: row;
    }

    .features-content-icon{
        align-self: center;
    }
}