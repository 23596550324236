#ExamModalOverlay{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.exam-modal-buttons-div{
    display: flex;
    margin: 0!important;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}

.btn-learn-the-unknown:hover{
    background-color: transparent!important;
    color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
}

.btn-retry-the-exam{
    background-color: transparent!important;
    color: var(--black-mid)!important;
    border-color: var(--black-mid)!important;
}

.disable-li-buttons{
    pointer-events: none;
    cursor: not-allowed;
    background-color: var(--gray-100)!important;
    color: var(--gray-300)!important;
}

.btn-retry-the-exam:hover{
    background-color: var(--black-mid)!important;
    color: var(--gray-50)!important;
    border-color: var(--gray-50)!important;
}

#ExamModalOverlay #endScreenPanelSplit{
    width: 100%;
    height: 100vh;
    background-color: var(--gray-50);
    border: 2px solid var(--gray-200);
    margin: 0 auto;
    overflow: scroll;
}

#ExamModalOverlay #endScreenPanelSplit::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

#ExamModalOverlay #endScreenPanelSplit::-webkit-scrollbar-thumb {
    background-color: var(--primary-400);
}

#ExamModalOverlay #endScreenPanelSplit::-webkit-scrollbar-track {
    background-color: transparent
}

#ExamModalOverlay #endScreenPanelSplit #closeModal{
    color: var(--red-mid)!important;
    font-size: 2.5rem;
    margin: 0!important;
    position: absolute;
    top: 1%;
    left: 1%;
    cursor: pointer;
    transition: var(--transition-2);
    z-index: 1000;
}

#ExamModalOverlay #endScreenPanelSplit #closeModal:hover{
    color: var(--red-dark)!important;
}

#ExamModalOverlay .questions-status{
    margin: 0 auto;
}

.top-screen-panel{
    margin-bottom: 1rem;
}

.exams-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 1rem;
    margin-top: 1rem;
}

.exams-nav li{
    background-color: var(--gray-100);
    border: 2px solid var(--gray-150);
    transition: var(--transition-2);
    border-radius: var(--radius);
    cursor: pointer;
    padding: 0.75rem 1rem;
}

.exams-nav li:hover{
    color: var(--gray-50);
    background-color: var(--primary-400);
    border-color: var(--primary-400);
}

.exams-nav li.selected{
    color: var(--gray-50);
    background-color: var(--primary-500);
    border: var(--primary-500);
}

@media screen and (min-width: 768px){
    #ExamModalOverlay  .questions-status {
        width: inherit;
        min-width: inherit;
    }

    .exam-modal-buttons-div{
        flex-direction: row;
    }
}
