.main-login {
  /* height: calc(100vh - 5rem); */
  min-height: calc(100vh - 5rem);
  height: auto;
  background-color: var(--gray-100);
  padding: 1rem;

  background: url('../../images/geometry2.png') 100% / contain repeat fixed;
  /* background-size: 9rem; */

  background-size: 12rem;

  padding-top: 6rem;
}

.animation-parent-login {
  margin-bottom: 1rem;
}

/* .recpatcha-container div:nth-of-type(3){
    height: 70px!important;
    text-align: center!important;
    display: flex!important;
    margin: 0 auto!important;
    width: 95%!important;
} */

.login-container {
  background-color: var(--gray-50);
  width: 95vw;
  height: 32rem;
  min-height: auto;
  max-width: 1120px;
  max-height: 60rem;
  border-radius: var(--radius);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1);

  transition: var(--transition-2);
  overflow: hidden;
  height: 100%;
}

.login-container:hover {
  /* transform: scale(1.01); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1);
}

.login-col-1 {
  display: none;
  height: 100%;
  width: 100%;
}

.login-col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1rem;
}

.login-banner h1 {
  text-transform: capitalize;
  font-family: var(--ff-heading);
  font-weight: 600;
  color: var(--primary-500);
  letter-spacing: var(--letter-spacing);
}

.login-banner p {
  font-family: var(--ff-body);
  font-weight: 200;
  color: var(--gray-500);
  line-height: 1.25;
}

.login-body {
  width: 100%;
}

.login-form-input-row {
  background-color: var(--gray-100);
  border: 1px solid transparent;
  outline: none;
  margin: 0;
  transition: var(--transition-2);
  text-align: center;
  padding-top: 0.5rem;
  cursor: text;
  max-width: 20rem;
  margin: 0 auto;

  position: relative;

  border-radius: var(--radius);
  margin-bottom: 1rem;
}

.login-form-email-row {
  /* border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius); */
}

.login-form-input-row.valid {
  background-color: var(--green-light) !important;
}

.login-label-focused,
.login-i-focused {
  color: var(--primary-500) !important;
  transition: var(--transition-2);
}

.login-form-password-row {
  /* border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius); */
  /* margin-bottom: 1rem; */
}

.login-row-focused {
  background-color: var(--gray-200);
}

.login-form-input-row .form-login-input-icon {
  color: var(--gray-700);
  font-size: 1rem;
  display: block;
  position: absolute;
  top: 20%;
  left: 1rem;
}

.login-form-input-row label {
  color: var(--gray-700);
  font-size: 1rem;
  font-family: var(--ff-body);
  font-weight: 100;
  letter-spacing: var(--letter-spacing);
  display: block;
  cursor: text;
  text-transform: capitalize;
}

.login-form-input-row input {
  border: 1px solid transparent;
  background-color: transparent;
  transition: var(--transition-2);
  width: 90%;
  letter-spacing: 0.15rem;
  text-align: center;
}

.login-form-input-row input:focus {
  border: 1px solid transparent;
  outline: none;
}

.password-visibility {
  display: inline-block !important;
  margin-left: 0.25rem;
  cursor: pointer;
  transition: var(--transition-2);
  font-size: 1.25rem;
}

.password-visibility:hover {
  color: var(--gray-900);
}

#loginPassword {
  width: 80% !important;
}

.login-form-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}

.login-form-btn {
  border-radius: var(--radius-half-circle) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  font-size: 0.8rem;
}

.login-form-submit:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  transform: scale(1.01) !important;
  border: 2px solid var(--primary-500) !important;
  font-family: var(--ff-body);
}

.login-form-register {
  background-color: var(--gray-900) !important;
  color: var(--gray-50) !important;
  border: 2px solid var(--gray-900) !important;
}

.login-form-register:hover {
  background-color: var(--gray-100) !important;
  color: var(--gray-900) !important;
  border: 2px solid var(--gray-900) !important;
}

.login-form-forgot-password {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--gray-900) !important;
  font-size: 0.75rem !important;
  margin: 0 !important;
  font-family: var(--ff-body) !important;
  font-weight: 600 !important;
  margin-top: 1rem !important;
}

.login-form-forgot-password:hover {
  color: var(--primary-500) !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px var(--gray-200) inset;
  background-color: none; /* Change the background color */
  border: 1px solid var(--gray-200); /* Change the border color */
}

.form-alert-login {
  margin: 0 !important;
  margin-top: 1rem !important;
}

@media screen and (min-width: 768px) {
  .login-form-btns {
    flex-direction: row;
  }

  .login-form-input-row .form-login-input-icon {
    top: 50%;
    left: 1rem;
    font-size: 1.5rem;
    transform: translateY(-50%);
  }

  .login-form-input-row input {
    width: 60% !important;
  }

  #loginPassword {
    width: 60% !important;
  }

  .password-visibility {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1024px) {
  .main-login {
    background-size: 20rem;
  }

  .login-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
  }

  .login-col-1 {
    grid-column: 1;
    display: block !important;
    position: relative;

    opacity: 0;
    transform: translateX(50%);
    transition: all 1s;
  }

  .login-col-1.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .login-col-1 .login-wallpaper {
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    position: absolute;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .login-logo {
    width: 10rem;
    height: 9rem;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
  }

  .login-col-2 {
    grid-column: 2;

    opacity: 0;
    transform: translateX(-50%);
    transition: all 1s;
  }

  .login-col-2.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .login-form-btns-hebrew {
    flex-direction: row-reverse;
  }
}
