body{
    background: url("../../images/geometry2.png") 100%/contain repeat fixed;
    background-size: 12rem;
}

#sectionFeedback2{
    padding-top: 8rem;
    height: 100%;
    min-height: calc(100vh - 5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0!important;
}

.section-feedback{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.fform-cell1{
    grid-column: 1/ -1;
    grid-row: 1;
}

.fform-cell2{
    grid-column: 1/ -1;
    grid-row: 2;
}

.fform-cell3{
    grid-column: 1/ -1;
    grid-row: 3;
}

.fform-cell4{
    grid-column: 1/ -1;
    grid-row: 4;
}

@media screen and (min-width: 1024px){
    #fform {
        display: block!important;
    }
}