#main{
    min-height: calc(100vh - 5rem);
    height: 100%;
}

#mainTopNav{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
}

#mainTopNav ul{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 0!important;
    gap: 1rem;
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
    height: 4rem;
}

#mainTopNav ul li{
    letter-spacing: var(--letter-spacing);
    color: var(--gray-900);
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 900;
    cursor: pointer;
    font-family: var(--ff-heading);
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3); 
    text-underline-offset: 10px;
    transition: var(--transition-2);
}

#mainTopNav ul li:hover{
    color: var(--primary-400);
    text-decoration-color: rgba(26, 86, 219, 0.6);
    text-underline-offset: 15px;
}

#mainTopNav ul li.selected{
    color: var(--primary-500);
    text-decoration-color: rgba(26, 86, 219, 1);
    text-underline-offset: 15px;
}

.reverse-ul-hebrew{
    flex-direction: row-reverse;
}