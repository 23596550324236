#SortExamsDiv{
    border: 2px solid var(--gray-200);
    background: var(--gray-100);
    height: 5rem;
    width: 90%;
    margin: 0 auto 2rem auto;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 1rem; */
}

#SortExamsDiv div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

#SortExamsDiv p{
    margin: 0!important;
    color: var(--primary-400);
    letter-spacing: var(--letter-spacing);
    text-transform: capitalize;
}

#direction, #data{
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 0;

    transition: var(--transition-2);
}

#direction li, #data li{
    color: var(--gray-900);
    transition: var(--transition-2);
    cursor: pointer;
}

#direction li:hover, #data li:hover{
    color: var(--primary-400);
}

#direction li.selected, #data li.selected{
    color: var(--primary-500);
    transition: var(--transition-2);
}

@media screen and (min-width: 375px){
    .sort-exams-sep{
        display: none;
    }
}

