.nav {
  position: fixed;
  /* margin-bottom: 15rem; */
  height: 5rem;
  width: 100%;
  background-color: var(--gray-50);
  box-shadow: var(--shadow-1);
  border-bottom: 1px solid var(--gray-200);
  /* display: flex; */
  display: grid !important;
  grid-template-columns: repeat(3, auto) !important;
  grid-template-rows: repeat(1, 1fr) !important;
  z-index: 1500;

  transition: all 0.2s ease-in;

  top: 0;
}

#nav2 {
  /* position: relative; */
  position: fixed;
}

.navbar-login {
  /* position: relative; */
}

.nav::after,
.nav::before {
  content: none !important;
}

#languageSelect2 {
  display: none;
}

.navbar-section {
  height: 100%;
  flex: 1;
  width: 0;
  margin: 0;
  padding: 0;
}

.navbar-brand {
  width: 100%;
}

.show-nav {
  transform: translateY(0);
  transition: all 0.1s ease-in;
  opacity: 1;
}

.hide-nav {
  transform: translateY(-100%);
  transition: all 0.2s ease-in;
  opacity: 0;
}

.navbar-img {
  width: 100%;
  height: 100%;

  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.navbar-img a {
  color: var(--color-txt);
  font-family: var(--ff-body);
  font-weight: 600;
}

.navbar-img a:hover {
  text-decoration: none;
}

.navbar-img img {
  min-width: 60px;
  max-width: 60px;
  /* height: 100%; */
}

.navbar-img-label {
  padding-left: 0.25rem;
}

.navbar-list {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar-toggle-button {
  display: none !important;
}

.navbar-list-button,
.navbar-toggle-button {
  margin-bottom: 0 !important;
  background-color: var(--primary-500) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75rem;
}

.navbar-list-button:hover,
.navbar-toggle-button:hover {
  background-color: var(--primary-600) !important;
  color: var(--white);
}

.navbar-list-button:focus,
.navbar-toggle-button:focus {
  outline: 0.4rem solid var(--gray-200);
}

.navbar-list-items {
  text-decoration: none;
  list-style-type: none;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.navbar-list-items-reverse {
  flex-direction: row-reverse;
}

.aside-list-items-reverse {
  text-align: right;
  float: right;
}

.sidebar {
  padding-bottom: 5rem;
  transform: translateX(-5000px);
  transition: var(--transition-2);
  position: fixed;
  z-index: 2;
  width: 100%;
  background-color: var(--gray-50);
  padding-top: 6rem;
  box-shadow: var(--shadow-2);

  transition: all 0.2s ease-in;
  overflow-y: scroll;
  height: 100%;
  max-height: 30rem;
}

#sidebar.sidebar-2 {
  padding-top: 4rem;
}

.special-sidebar {
  padding-top: 1rem !important;
}
/* 
.special-sidebar .navbar-toggle-items {
  overflow-y: scroll !important;
  height: 90% !important;
} */

.sidebar-login {
  /* padding-top: 0; */
}

.navbar-toggle-items {
  display: flex;
  text-decoration: none;
  list-style-type: none;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  margin: 0;
}

.show-aside {
  transform: translateY(0);
  transition: all 0.2s ease-in;
  opacity: 1;
  z-index: 1000;
  /* position: absolute; */
}

.hide-aside {
  transform: translateY(-100%);
  transition: all 0.2s ease-in;
  opacity: 0;
  z-index: -1000;
  position: fixed;
}

.aside-item {
  width: 100%;
  transition: var(--transition-2);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  text-align: center;
}

.aside-item a {
  text-align: center;
}

.aside-item:hover {
  background-color: var(--gray-300);
}

.aside-link {
  width: 80%;
  border-bottom: 1px solid var(--gray-300);
}

/* GRAY OUT */
.nav-link.gray-out {
  opacity: 0.2;
  color: var(--gray-900);
  pointer-events: none;
}

.nav-link {
  cursor: pointer;
  line-height: 1;
  transition: var(--transition-2);
}

.nav-link:hover {
  color: var(--primary-400) !important;
  text-decoration: none;
}

.nav-link:focus {
  color: var(--primary-500) !important;
}

.active {
  color: var(--primary-600);
}

.navbar-toggle {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar-toggler {
  font-size: 2rem;
  transition: var(--transition-2);
  color: var(--gray-400);
}

.navbar-toggler:hover {
  font-size: 2.5rem;
  background-color: var(--gray-100);
  color: var(--gray-500);
}

.navbar-toggler:focus {
  outline: none;
}

.btn-login-cnt,
.btn-login-cnt2 {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-login-cnt2 {
  display: none;
  margin: 0 0 0 2rem !important;
  width: auto;
}

.btn-login-cnt2 a {
  margin: 0 !important;
  transition: var(--transition-2);
}

.btn-login-cnt2 a:hover {
  color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
  background-color: transparent !important;
}

.btn-login {
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  transition: var(--transition-2);
  background-color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
  border-radius: 0.5rem !important;
  color: var(--gray-50) !important;
  font-family: var(--ff-heading);
  font-size: 1rem;
  font-weight: 600 !important;
  margin-top: 1.5rem;
}

.btn-account {
  background-color: var(--gray-100) !important;
  border: 2px solid var(--gray-200) !important;
  color: var(--primary-500) !important;
  font-weight: 600 !important;

  margin-top: 1rem;
}

.dropdown-menu-custom-navbar {
  display: flex;
  flex-direction: row;
  gap: 0 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#dropdown-variants-profile {
  display: flex;
  flex-direction: row;
  gap: 0 5px;
  justify-content: center;
  align-items: center;

  background-color: var(--gray-100) !important;
  border: 2px solid var(--gray-200) !important;
  color: var(--primary-500) !important;
  font-weight: 600 !important;

  margin-top: 1rem;

  cursor: pointer;
}

#dropdown-variants-profile.show {
  display: flex !important;
}

.dropdown-menu-custom-navbar-reverse {
  flex-direction: row-reverse;
}

.dropdown-menu {
  text-align: center;
}

.btn-login-cnt2 a:hover {
  color: var(--primary-500) !important;
  border: none !important;
  background-color: transparent !important;
}

@media screen and (min-width: 425px) {
  .navbar-list-button,
  .navbar-toggle-button {
    padding: 0.25rem 0.7rem !important;
    font-size: 1rem;
  }

  .navbar-img img {
    width: 90%;
  }

  .navbar-img-label {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1024px) {
  .navbar-toggler {
    display: none;
  }

  .navbar-list-button {
    display: none !important;
  }

  .navbar-list-items {
    display: flex;
  }

  .navbar-toggle-button {
    display: block !important;
  }

  .navbar-img img {
    width: 100%;
  }

  #languageSelect {
    display: none;
  }

  #languageSelect2 {
    display: block;
  }

  .btn-login-cnt2 {
    display: flex;
  }

  .nav {
    grid-template-columns: 10% 55% 35% !important;
  }
}

@media screen and (min-width: 1200px) {
  .nav {
    grid-template-columns: 30% 40% 30% !important;
  }
}
