#languageSelect{
    cursor: pointer;
    border: 1px solid var(--primary-300);
    background-color: var(--gray-50);
    color: var(--gray-900);
    letter-spacing: var(--letter-spacing);
    text-transform: capitalize;
    font-family: var(--ff-heading);
    font-weight: 600;
    padding: 0.1rem 0.25rem;
    transition: all 0.2s ease-in;
    text-align: center;
    border-radius: var(--radius);
    margin: 0rem auto 1rem;
}

#languageSelect:hover{
    border-color: var(--primary-500);
    background-color: var(--gray-100);
}

#languageSelect:focus-visible{
    border: 1px solid var(--primary-400);
    outline: none;
}
#languageSelect option{
    cursor: pointer;
    border: 1px solid var(--gray-50)!important;
    outline: none;
    background-color: var(--gray-200);
    color: var(--gray-900);
    letter-spacing: 0.5rem;
    text-transform: capitalize;
    font-family: var(--ff-body);

    transition: all 0.2s ease-in;
    text-align: center;
}

#languageSelect option:hover{
    border: 1px solid transparent;
    outline: none;
    background-color: var(--gray-300)!important;
}

#languageSelect2{
    cursor: pointer;
    border: 1px solid var(--primary-300);
    background-color: var(--gray-50);
    color: var(--gray-900);
    letter-spacing: var(--letter-spacing);
    text-transform: capitalize;
    font-family: var(--ff-heading);
    font-weight: 600;
    padding: 0.1rem 0.25rem;
    transition: all 0.2s ease-in;
    text-align: center;
    border-radius: var(--radius);
}

#languageSelect2:hover{
    border-color: var(--primary-500);
    background-color: var(--gray-100);
}

#languageSelect2:focus-visible{
    border: 1px solid var(--primary-400);
    outline: none;
}
#languageSelect2 option{
    cursor: pointer;
    border: 1px solid var(--gray-50)!important;
    outline: none;
    background-color: var(--gray-200);
    color: var(--gray-900);
    letter-spacing: 0.5rem;
    text-transform: capitalize;
    font-family: var(--ff-body);

    transition: all 0.2s ease-in;
    text-align: center;
}

#languageSelect2 option:hover{
    border: 1px solid transparent;
    outline: none;
    background-color: var(--gray-300)!important;
}

.language-class{
    display: none;
    width: 32px;
    height: 32px;
    -o-object-fit: cover;
       object-fit: cover;
}

.language-class-israel{
    background: url("../../images/israel.png") center/cover no-repeat!important;
}

.language-class-usa{
    background: url("../../images/usa.png") center/cover no-repeat!important;
}

@media screen and (min-width: 1024px){
    .language-class{
        margin-left: 1rem;
        display: block;
    }
}
