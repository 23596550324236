.study-card {
    height: 100%;
    width: 100%;

    transform-style: preserve-3d;

    position: relative;

    border-radius: 5px;
    border: 5px solid white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.8);

    user-select: none;
    cursor: pointer;
}

.study-card-front,
.study-card-back {
    height: 100%;
    width: 100%;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: var(--ff-body);
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: var(--letter-spacing);
    /* todo: break word? maybe bad for long words */
    word-wrap: break-word;
    color: var(--white);
    text-transform: capitalize;

    /* Add this line to center align text */
    text-align: center;


}

.study-card-text {
    max-height: 100%;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em;
}

.study-card-front {
    background-color: #5c49ee;
}

.study-card-back {
    background-color: #af49ee;
    transform: rotateY(180deg);
}

.flip-exit {
    transform: rotateY(0deg);
}

.flip-exit-active {
    transform: rotateY(180deg);
    transition: transform 300ms;
    /* transition-timing-function: cubic-bezier(.57,.21,.69,1.25); */
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.flip-exit-done {
    transform: rotateY(180deg);
}

.flip-enter {
    transform: rotateY(180deg);
}

.flip-enter-active {
    transform: rotateY(0deg);
    transition: transform 300ms;
    /* transition-timing-function: cubic-bezier(.57,.21,.69,1.25); */
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

}

.flip-enter-done {
    transform: rotateY(0deg);
}