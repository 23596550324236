.alert-overlay{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    margin: 1rem auto;
    /* left: 50%; */
    transform: translate(-50%, 0);
    width: 95vw;
    max-width: 30rem;
}

.alert-modal{
    width: 100%;
    max-width: var(--width-section);
    background-color: var(--black-mid);
    border: 2px solid var(--gray-900);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    padding: 1rem;
    transition: var(--transition-2);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.1), 0px 0px 30px rgba(0, 0, 0, 0.1);

    position: relative;

    border-radius: var(--radius);
    
}

.alert-modal .alert-modal-icon-container{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--gray-50);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid var(--gray-50);
    outline-offset: 10px;
    margin: 1rem 0 1.5rem 0;
}

.alert-modal .alert-modal-icon-container i{
    color: var(--gray-900);
    font-size: 1.5rem;
}

.alert-modal .fa-xmark{
    color: var(--gray-900);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 1.75rem;
    cursor: pointer;
    transition: var(--transition-2);
}

.alert-modal .fa-xmark:hover{
    color: var(--red-mid);
}

.password-modal-overlay.visible{
    z-index: 2500!important;
}

.password-modal-overlay{
    z-index: -2500!important;
}

.alert-modal-type{
    font-size: 1.25rem;
    letter-spacing: var(--letter-spacing);
    text-transform: uppercase;
    font-family: var(--ff-heading);
    font-weight: 600;
    color: var(--primary-600);
}

.alert-modal-type-warning, .alert-modal-type-warning-exam{
    color: var(--yellow-mid);
}

.alert-modal-type-information{
    color: var(--blue-mid);
}

.alert-modal-information{
    color: var(--gray-900);
}

.password-modal-overlay .alert-modal-type-password{
    color: var(--gray-900)!important;
}

.password-modal-overlay{
    /* margin: 19rem -35rem!important; */
    position: absolute!important;
}

.password-modal{
    background-color: var(--gray-100)!important;
    border: 2px solid var(--gray-200)!important;
    padding: 0!important;
}

.password-modal .alert-modal-icon-container{
    background-color: var(--gray-900)!important;
    outline: 2px solid var(--gray-900)!important;
}

.password-modal .alert-modal-icon-container i{
    color: var(--gray-50)!important;
    font-size: 1.5rem;
}

.alert-modal-information-list{
    list-style-type: disclosure-closed;
    padding: 1rem 2rem;
}

.alert-modal-type-error{
    color: var(--red-mid);
}

.alert-modal-type-success{
    color: var(--green-dark);
}

.alert-modal-title{
    color: var(--primary-500);
    font-family: var(--ff-heading);
    text-transform: capitalize;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: var(--letter-spacing);
}

.alert-modal-description-regular{
    color: var(--gray-50);
    font-family: var(--ff-body);
    text-transform: capitalize;
    font-size: 1rem!important;
    font-weight: 600!important;
    letter-spacing: var(--letter-spacing)!important;
}

.fade-in-bottom-alert { 
    opacity: 0;
    transform: translate(-50%, -10vh);
    transition: all 1s;
    z-index: -2000;
}

.fade-in-bottom-alert.visible {
    opacity: 1;
    transform: translate(-50%, 0);
    z-index: 2000;
}

#closeModal{
    color: var(--gray-50)!important;
}

#closeModal:hover{
    color: var(--red-mid)!important;
}

.password-modal-overlay .alert-modal-information-list {
    padding: 0.75rem 1.5rem;
    font-size: 0.75rem;
}

.password-modal-overlay .alert-modal-type-password{
    font-size: 0.75rem!important;
}

.password-modal-overlay .alert-modal-type {
    font-size: 1rem;
}

/* .register-password-modal{
    left: 97%!important;
} */

@media screen and (min-width: 768px){
    /* .password-modal-overlay {
        margin: 19rem -35rem!important;
    } */

    .password-modal-overlay .alert-modal-information-list {
        padding: 0.75rem 1.5rem;
        font-size: 1rem;
    }
    
    .password-modal-overlay .alert-modal-type-password{
        font-size: 1rem!important;
    }
    
    .password-modal-overlay .alert-modal-type {
        font-size: 1.25rem;
    }

    .register-password-modal .alert-modal-information-list {
        padding: 0.75rem 1.5rem;
        font-size: 0.75rem;
    }
    
    .register-password-modal .alert-modal-type-password{
        font-size: 0.75rem!important;
    }
    
    .register-password-modal .alert-modal-type {
        font-size: 1rem;
    }
}


@media screen and (min-width: 768px){
    .register-password-modal{
        left: 75%!important;
    }
}