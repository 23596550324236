#userSettings {
  margin-top: 1rem;
  font-size: 2rem;
}

#updateRowMobile {
  padding-top: 0 !important;
  height: 4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  border: 2px solid var(--primary-400);
  max-width: 20rem;
  width: 90%;
  background-color: var(--primary-400);
}

.delete-my-account {
  color: var(--red-mid);
  letter-spacing: var(--letter-spacing);
  text-align: center;
  margin-top: 1rem;
  cursor: pointer;
  transition: var(--transition-2);
  margin: 0 auto;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.delete-my-account:hover {
  color: var(--red-dark);
}

#updateRowMobile i {
  color: var(--gray-50);
  top: 50%;
  left: 1rem;
  font-size: 1.5rem;
  transform: translateY(-50%);
}

#updateRowMobile p {
  margin: 0 !important;
  cursor: pointer;
  letter-spacing: var(--letter-spacing);
  color: var(--gray-50);
}

#updateRowMobile:hover {
  background-color: transparent;
}

#updateRowMobile:hover p {
  color: var(--primary-400);
}

#updateRowMobile:hover i {
  color: var(--primary-400);
}

.settings-option {
  margin: 0 auto;
  width: 80%;
  max-width: var(--width-section);
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  height: 5rem;
  background-color: var(--gray-100);
  border-radius: var(--radius);
  border: 2px solid var(--gray-200);

  z-index: -1000;
  opacity: 0;
  transform: translateX(-10vw);
  transition: 0.3s ease-in-out all;
}

.settings-option.visible {
  z-index: 1000;
  opacity: 1;
  transform: translateX(0);
}

.settings-option.reverse-list {
  flex-direction: row-reverse;
}
/* GRAY OUT */
.settings-option-item.gray-out {
  opacity: 0.2;
  color: var(--gray-900);
  pointer-events: none;
}

.settings-option-item {
  font-size: 1.25rem;
  cursor: pointer;
  color: var(--gray-900);
  transition: var(--transition-2);
}

.settings-option-item.clicked {
  color: var(--primary-500);
}

.settings-option-item:hover {
  color: var(--primary-500);
}

.settings-profile {
  max-width: var(--width-max);
  margin: 0 auto;
  margin-top: 1rem;
  height: auto;
  width: 90%;
}

.settings-parent {
  z-index: -1000;
  opacity: 0;
  transform: translateX(-10vw);
  transition: 0.3s ease-in-out all;
}

.settings-parent.visible {
  z-index: 1000;
  opacity: 1;
  transform: translateX(0);
}

.settings-profile form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.settings-profile form label {
  color: var(--gray-900);
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  font-size: 1.25rem;
}

.btn-update {
  border: 2px solid var(--primary-500);
}

.btn-update:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border-color: var(--primary-500) !important;
}

.update-icon {
  position: absolute;
  left: 16rem;
  top: 30%;
  transform: translateY(-30%);
  font-size: 1.5rem;
  cursor: pointer;
  transition: var(--transition-2);
  opacity: 0;
  z-index: -1000;
}

.update-icon:hover {
  color: var(--green-dark);
}

.update-icon.show-update-icon {
  opacity: 1;
  z-index: 1000;
}

.settings-main {
  min-height: calc(100vh - 5rem);
  height: 100%;
}

#updateRowPassword {
  margin-top: 3rem;
}

.settings-cookies-p {
  max-width: 50rem;
  text-align: center;
  margin: 0 auto;
}

.privacy-policy-button,
.cookies-policy-button {
  transition: var(--transition-2) !important;
  text-decoration: underline;
  text-decoration-color: var(--gray-200);
  text-underline-offset: 0.5rem;
  outline: none !important;
}

.privacy-policy-button:hover,
.cookies-policy-button:hover {
  color: var(--primary-400);
  text-decoration: underline !important;
  text-decoration-color: var(--primary-400) !important;
  text-underline-offset: 0.75rem !important;
}

.form-row-condition-cookies-settings {
  margin-top: 1rem !important;
}

@media screen and (min-width: 425px) {
  .settings-policy-buttons {
    flex-direction: row;
    gap: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .update-icon {
    left: 20.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
