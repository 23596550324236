#contactCategory{
    margin: 0 auto;
    width: 90vw;
    transition: var(--transition-2);
}

.recaptcha-container-contactus {
    grid-column: 1/-1;
}

#contactCategory .css-1nmdiq5-menu{
    z-index: 5000;
    position: static;
    transition: height 0.2s ease-in, opacity 0.2s ease-in;
}

#contactCategory .css-1nmdiq5-menu::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
#contactCategory .css-1nmdiq5-menu::-webkit-scrollbar-thumb {
    background-color: var(--gray-100);
    border-radius: 10px;
}

#contactCategory .css-1nmdiq5-menu::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
    margin-left: 1rem!important;
}

.contact-category-clicked{
    height: auto;
    transition: height 0.2s ease-in, opacity 0.2s ease-in;
    opacity: 1;
}

.contact-category-not-clicked{
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-in, opacity 0.2s ease-in;
}

.form-cell-english{
    margin-left: 0;
    margin-right: 0!important;
}

@media screen and (min-width: 768px){
    #contactCategory{
        margin: 0 auto;
        width: 50vw;
    }
}

@media screen and (min-width: 1024px){
    .form-cell-english {
        margin-right: 1rem!important;
    }
    .cform-reverse-items .cform-cell2, .cform-reverse-items .cform-cell3{
        margin-right: 0;
        margin-left: 1rem;
    }

    .cform{
        display: grid!important;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }

    .cform-cell1 {
        grid-row: 1 / span 1 !important;
        grid-column: 1 / span 2;
      }
      
    .cform-cell2 {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
        margin-right: 1rem;
    }
    
    .cform-cell3 {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
        margin-right: 1rem;
        align-self: end!important;
    }
    
    .cform-cell4 {
        grid-row: 2 / span 2;
        grid-column: 2 / span 1;
    }

    .cform-cell5 {
        width: 100%;
        grid-column: 1 / -1;
        margin-top: 0.25rem;
    }

    .form-row {
        align-self: start;
    }
      
}