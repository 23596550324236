.results-item{
    background-color: var(--gray-200);
    padding: 2px 10px;
    border-radius: var(--radius-half-circle);
    text-align: center;
    margin: 0;
    cursor: pointer;
    flex: 0 0 auto; /* Prevent items from growing or shrinking */
    height: 100%;
}

.results-item:hover .append-word{
    color: var(--primary-500);  
}
    
.results-item-parent .append-word{
    left: 0!important;
    right: 0!important;
    bottom: 0!important;
    position: relative!important;
    font-size: 1rem!important;
    margin-left: 5px!important;
    top: 1px!important;
}