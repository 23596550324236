.products-content {
  display: grid;
  gap: 1.5rem 0;
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
}

.product-card {
  border-radius: 0.5rem;
  background-color: var(--gray-700);
  padding: 1rem;
  max-width: 400px;
  height: 600px;
  margin: 0 auto;

  display: grid;
  grid-template-rows: repeat(3, auto);
  transition: var(--transition-2);
  border: 1px solid var(--gray-800);
  position: relative;
  padding-bottom: 0;
}

/* GRAY OUT */
.product-card.gray-out {
  opacity: 0.2;
  background-color: var(--gray-700);
  color: var(--gray-100);
  border: 1px solid var(--gray-800);

  pointer-events: none;
}

.product-card.gray-out .free-product {
  color: var(--gray-100);
}

.product-card.gray-out .a-register {
  color: var(--gray-100);
  border-color: var(--gray-100) !important;
}

.free-product {
  letter-spacing: 0.5rem !important;
}

.discount-tag {
  margin: 0 !important;
  margin-top: -0.25rem !important;
  font-family: var(--ff-heading) !important;
  width: 100%;
  background-color: var(--gray-200);
  border-radius: var(--radius);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.discount-tag p {
  margin: 0 !important;
  color: #25bf25 !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
}

.discount-tag span {
  margin: 0 !important;
  color: #25bf25 !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
}

.discount-tag-blue,
.discount-tag-dark {
  margin: 0 !important;
  font-family: var(--ff-heading) !important;
  margin-top: -0.25rem !important;
  width: 100%;
  background-color: var(--primary-700) !important;
  border-radius: var(--radius);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.discount-tag-blue p,
.discount-tag-dark p {
  margin: 0 !important;
  color: var(--green-mid) !important;
  /* color: #25bf25!important; */
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
}

.discount-tag-blue span,
.discount-tag-dark span {
  margin: 0 !important;
  color: var(--green-mid) !important;
  /* color: #25bf25!important; */
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
}

.discount-tag-dark {
  background-color: var(--gray-800) !important;
}

.product-card:hover {
  transform: scale(1.02);
  box-shadow: var(--shadow-2);
}

.model-e-card .product-card-banner h2 {
  font-size: 1.5rem;
}

.product-card-banner {
  border-bottom: 1px solid var(--primary-900);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.5rem;
}

.product-card-banner h2 {
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-family: var(--ff-heading);
  font-weight: 600;
  letter-spacing: var(--letter-spacing);
  font-size: 1.75rem;
}

.product-card-banner h1 {
  text-align: center;
  color: var(--primary-400);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-family: var(--ff-heading);
  font-weight: 600;
  font-size: 4rem;
  margin: 0;
}

.product-card-banner p {
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  letter-spacing: 0.05rem;
  font-family: var(--ff-body);
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0;
}

.product-card-description {
  border-bottom: 1px solid var(--primary-900);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.product-card-description p {
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing);
  font-family: var(--ff-heading);
  font-weight: 600;
  letter-spacing: var(--letter-spacing);
  font-size: 1.25rem;
}

.product-card-description ul {
  list-style-type: none;
  margin: 0;
}

.product-card-description ul li {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.5rem;
  text-align: center;
}

.product-card-description ul li p {
  margin: 0;
  font-size: 0.85rem;
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing) !important;
  font-family: var(--ff-body);
  font-weight: 600;
  letter-spacing: var(--letter-spacing);
}

.product-card-description ul li span {
  color: var(--green-mid);
  margin: 0;
  font-size: 1.1rem;
}

.product-card-enrollment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem 0;
  padding-top: 0.5rem;
}

.product-card-enrollment a {
  margin: 0;
}

.product-card-enrollment .a-register {
  border-radius: var(--radius-half-circle);
  background-color: transparent;
  border: 2px solid var(--primary-500);
}

.model-e-card .product-card-enrollment .a-register {
  border-radius: var(--radius-half-circle);
  background-color: transparent;
  border: 2px solid var(--primary-500);
  color: var(--gray-100) !important;
}

.product-card-enrollment .a-register:hover {
  background-color: var(--primary-500);
  transform: scale(1.1);
  color: var(--gray-100) !important;
}

.product-card-enrollment .a-login {
  background-color: transparent;
  font-size: 0.85rem;
  box-shadow: none;
  color: var(--gray-50);
  letter-spacing: var(--letter-spacing);
}

.product-card-enrollment .a-login:hover {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid var(--gray-200) !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: var(--gray-200) !important;
}

/* Psychometry card */
.psychometry-card {
  background-color: var(--gray-100);
  border: 1px solid var(--gray-200);
}

.psychometry-card .product-card-banner {
  border-color: var(--gray-300);
}

.psychometry-card .product-card-banner h2,
.psychometry-card .product-card-banner p {
  color: var(--gray-900);
}

.psychometry-card .product-card-description {
  border-color: var(--gray-300);
}

.psychometry-card .product-card-description h2,
.psychometry-card .product-card-description p {
  color: var(--gray-900);
}

.psychometry-card .product-card-enrollment a {
  color: var(--gray-900);
}

.psychometry-card .product-card-enrollment .a-register:hover {
  color: var(--gray-100);
}

.psychometry-card .product-card-enrollment .a-login:hover {
  border-bottom-color: var(--gray-900) !important;
  color: var(--gray-900) !important;
}

/* Custom card */
.custom-card {
  background-color: var(--primary-600);
  border: 1px solid var(--primary-700);
}

.custom-card .product-card-banner {
  border-color: var(--primary-500);
}

.custom-card .product-card-banner h2,
.custom-card .product-card-banner p {
  color: var(--gray-50);
}

.custom-card .product-card-banner h1 {
  color: var(--gray-800);
}

.custom-card .product-card-description {
  border-color: var(--primary-500);
}

.custom-card .product-card-description h2,
.custom-card .product-card-description p {
  color: var(--gray-50);
}

.custom-card .product-card-enrollment a {
  color: var(--gray-v);
}

.custom-card .product-card-enrollment .a-register {
  color: var(--gray-50);
  border-color: var(--gray-800);
}

.custom-card .product-card-enrollment .a-register:hover {
  /* color: var(--primary-600); */
  color: var(--gray-100) !important;
  background-color: var(--gray-800);
}

.custom-card .product-card-enrollment .a-login {
  color: var(--gray-50);
}

.custom-card .product-card-enrollment .a-login:hover {
  border-bottom-color: var(--gray-50);
}

@media screen and (min-width: 375px) {
  .model-e-card .product-card-banner h2 {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .products-content {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 2rem 2.25rem !important;
  }
}
