#UnitExamModal{
    background-color: var(--gray-100);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 15rem;
    height: 20rem!important;

    border-color: var(--primary-500);

    transition: var(--transition-2);
    z-index: 5000;
}

#UnitExamModal ul{
    list-style-type: none;
    height: 100%;
    width: 100%;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#closeExamModal{
    color: var(--gray-900)!important;
}

#closeExamModal:hover{
    color: var(--primary-400)!important;
}

.alert-modal .fa-arrow-rotate-left{
    color: var(--gray-900);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 1.75rem;
    cursor: pointer;
    transition: var(--transition-2);
}


.alert-modal .fa-arrow-rotate-left:hover{
    color: var(--primary-400);
}
