.reverse-items{
    flex-direction: row-reverse;
}

.faq-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem 0;
}

.faq-content-question{
    background-color: var(--gray-200);
    border-radius: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    max-width: calc(var(--width-section) + 100px);
    margin: 0 auto;
    transition: var(--transition-2);
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.faq-content-question > .faq-content-question-header > span > i, .faq-content-question > .faq-content-question-header > p{
    transition: var(--transition-2);
}

.faq-content-question:hover > .faq-content-question-header > span > i, .faq-content-question:hover > .faq-content-question-header > p{
    color: var(--primary-500);
}


.faq-clicked{
    max-height: 24rem;
    transition: max-height 0.2s ease-in, opacity 0.2s ease-in;
    opacity: 1;
}

.faq-not-clicked{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in, opacity 0.2s ease-in;
}

.faq-content-question-header{
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    transition: var(--transition-2);
}

.faq-content-question-header p{
    color: var(--gray-900);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-family: var(--ff-heading);
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
}

.faq-content-question-header span{
    color: var(--gray-900);
    font-size: 1rem;
}

.hide-faq-symbol{
    display: none;
}

.show-faq-symbol{
    display: block;
}

.faq-content-question-body{
    max-height: 100%;
    overflow: hidden;
    transition: max-height 0.2s ease-in, opacity 0.2s ease-in;
}

.faq-content-question-body p{
    color: var(--gray-600);
    letter-spacing: 0.05rem;
    font-family: var(--ff-body);
    font-weight: 600;
    margin: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}