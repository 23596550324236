.alert-overlay{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    margin: 1rem auto;
    left: 50%;
    transform: translate(-50%, 0);
    width: 95vw;
    max-width: 30rem;
}

.alert-overlay-email-verification{
  width: 100vw!important;
  height: 100vh!important;
  z-index: -1000;
  max-width: none!important;
}

#closeModal2{
  color: var(--gray-900)!important;
}

#closeModal2:hover{
  color: var(--red-mid)!important;
}

.alert-modal .fa-xmark{
    color: var(--gray-900);
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 1.75rem;
    cursor: pointer;
    transition: var(--transition-2);
}

.alert-modal-title{
    color: var(--primary-500);
    font-family: var(--ff-heading);
    text-transform: capitalize;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: var(--letter-spacing);
    margin-bottom: 1rem;
}

.alert-modal-description{
    color: var(--gray-900);
    font-family: var(--ff-body);
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: var(--letter-spacing);
}

.fade-in-bottom-email-verification { 
    opacity: 0;
    transform: translateY(-10vh);
    transition: all 1s;
    z-index: -1000;
    width: 90%;
    background-color: var(--gray-50);
    font-weight: 600!important;
    font-family: var(--ff-heading);
    border: 2px solid var(--primary-500);
}

.fade-in-bottom-email-verification .alert-modal-title{
  /* color: var(--green-dark); */
}

.fade-in-bottom-email-verification.visible {
    opacity: 1;
    transform: translateY(0);
    z-index: 1000;
}

.verification-code {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Adjust the gap between blocks as needed */
}

.code-input.valid, .code-input.valid, .code-input.valid, .code-input.valid, .code-input.valid, .code-input.valid{
  border-color: var(--green-mid);
  border-width: 2px;
}

#code-input-0:focus, #code-input-0:hover, #code-input-1:focus, #code-input-1:hover, #code-input-2:focus, #code-input-2:hover, #code-input-3:focus, #code-input-3:hover, #code-input-4:focus, #code-input-4:hover, #code-input-5:focus, #code-input-5:hover{
  border-width: 2px;
}


.code-input {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 1rem; 
  background-color: var(--gray-50);
  border: 1px solid var(--primary-500);
  text-transform: uppercase;
  border-radius: var(--radius);
  outline: none;
}

.btn-email-verify{
  margin: 0!important;
  margin-top: 1rem!important;
  font-weight: 600!important;
}

.btn-email-verify:hover{
  color: var(--gray-900)!important;
  background-color: transparent!important;
  border: 2px solid var(--primary-500);
  font-weight: 600!important;
}

.alert-overlay-email-verification.visible{
  z-index: 1000;
}

.email-verify-btn{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.btn-email-verify-new-code{
  background-color: transparent!important;
  color: var(--gray-900)!important;
  margin: 0!important;
  margin-top: 1rem!important;
  font-weight: 400!important;
  font-family: var(--ff-heading);
  font-size: 0.75rem;
  cursor: pointer;
  box-shadow: none!important;
  height: auto!important;
  width: auto!important;
  padding: 0!important;
}


.btn-email-verify-new-code:hover{
  color: var(--primary-500)!important;
  transform: scale(1.02);

}