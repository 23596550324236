.not-allowed-class-arrow-btn{
    cursor: not-allowed;
    color: var(--gray-400);
}

.not-allowed-class-arrow-btn:hover{
    color: var(--gray-400);
}

.allowed-class-arrow-btn{
    cursor: pointer;
}

.study-mode-arrows-container{
    display: flex;
}

.study-mode-classification-arrows{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.btn-pause-screen-study {
    margin-bottom: 0 !important;
}