#emailVerificationPage {
  background: url('../../images/geometry2.png') 100% / contain repeat fixed;
  background-size: 12rem;
  top: 10%;
}

#emailVerificationPage .alert-modal {
  height: 30rem !important;
}

#emailVerificationPage.visible {
  z-index: 900;
}

.emailVerificationPageNavBar {
  z-index: 2000;
}

#verifyEmailEmail {
  border: 2px solid var(--primary-500);
}

#emailVerificationEmailLabel {
  width: 100%;
  margin-top: 1rem;
}

#emailVerificationEmailLabel label {
  color: var(--gray-900);
}

#emailVerificationEmailInput {
  width: 100%;
}
