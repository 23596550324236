#studyPauseScreen {
    width: 100vw;
    height: 100vh;
    z-index: 5000;
    background-color: var(--gray-700a);
    opacity: 1;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

#studyScreenPanel {
    width: 20rem;
    height: fit-content;
    max-height: 90vh;
    display: flex;
    overflow-y: auto;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: white;
}

#studyScreenPanel h1 {
    font-size: 2.5rem;
    color: var(--primary-500);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
}

#studyScreenPanel h3 {
    font-size: 1.5rem;
    color: var(--primary-400);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.pause-menu-title {
    color: var(--primary-400);
    margin-top: 1rem;
}

#studyScreenPanel p {
    font-size: 1.25rem;
    color: var(--gray-900);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
    margin: 1.5rem 0 0.5rem 0 !important;
}

#studyScreenPanel ul {
    list-style: none;
    font-size: 1rem;
    color: var(--gray-900);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing);
    font-weight: 500;
    font-family: var(--ff-body);
}

#studyScreenPanel ul li span {
    font-weight: 600;
}

.btn-start-screen-study:hover {
    background-color: transparent !important;
    color: var(--primary-500) !important;
    border-color: var(--primary-500) !important;
    margin-bottom: 1rem;
}

.btn-pause-screen-study {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -1%);
    top: 3%;

    background-color: transparent !important;
    color: var(--red-mid) !important;
    border-color: var(--red-mid) !important;
}

.btn-pause-screen-study:hover {
    background-color: var(--red-mid) !important;
    color: var(--gray-50) !important;
    border-color: var(--red-mid) !important;
}

#startExamBtn.gray-out-btn {
    cursor: not-allowed !important;
    background: var(--primary-700) !important;
    color: var(--gray-500) !important;
}

#startExamBtn.gray-out-btn:hover {
    cursor: not-allowed !important;
    background: var(--primary-800) !important;
    color: var(--gray-500) !important;
}



@media screen and (min-width: 425px) {
    #studyScreenPanel {
        width: 25rem;
    }

    #studyScreenPanel h3 {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 425px) {
    #studyScreenPanel {
        width: 35rem;
    }
}

@media screen and (min-width: 768px) {}