/* GRAY OUT */
.register-plan.gray-out {
  opacity: 0.2;
  background-color: var(--gray-700);
  color: var(--gray-100);
  border: 1px solid var(--gray-800);

  pointer-events: none;
}

.register-plan.gray-out .free-product {
  color: var(--gray-100);
}

.register-plan.gray-out .a-register {
  color: var(--gray-100);
  border-color: var(--gray-100) !important;
}

.main-register {
  /* height: calc(100vh - 5rem); */
  min-height: calc(100vh - 5rem);
  height: auto;
  background-color: var(--gray-100);
  padding: 1rem;
  background: url('../../images/interlaced.png') 100% / contain repeat fixed;
  background: url('../../images/geometry2.png') 100% / contain repeat fixed;
  /* background-size: 9rem; */

  background-size: 12rem;
  padding-top: 6rem;
}

.recaptcha-container-register {
  grid-column: 1/-1;
  grid-row: 8;
}

.register-enterprise-show {
  display: grid !important;
}

.register-enterprise-hide {
  display: none !important;
}

.register-plans-container {
  background-color: var(--gray-50);
  width: 80vw;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1);
  transition: var(--transition-2);
  border-radius: var(--radius);
  max-width: 1120px;
}

.section-plan-billing-duration {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section-plan-billing-duration .slider:before {
  background-color: var(--primary-500);
}

.section-plan-billing-duration .slider {
  background-color: var(--gray-200);
}

.section-plan-billing-duration input:checked + .slider {
  background-color: var(--gray-200);
}

.section-plan-billing-duration input:checked + .slider:before {
  transform: translateX(26px);
  box-shadow: 0 0 1px var(--gray-300);
}

.section-plan-billing-duration .slider:hover {
  background-color: var(--gray-300);
}

.section-plan-billing-duration p {
  margin: 0;
  letter-spacing: var(--letter-spacing);
  color: var(--primary-600);
  font-family: var(--ff-heading);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.15rem;
}

.section-plan-billing-duration-switch p {
  margin: 0;
  color: var(--gray-700);
}

.section-register-plans {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem !important;
  gap: 1rem 0;
}

.section-register-plans .section-banner .section-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.section-register-plans .section-banner .title-underline {
  width: 10rem !important;
  height: 0.15rem !important;
  margin-bottom: 1rem !important;
}

.section-register-plans .section-banner p {
  max-width: 15rem;
  margin: 0 auto;
  text-align: center;
  color: var(--gray-500);
  font-family: var(--ff-body);
  letter-spacing: var(--letter-spacing) !important;
}

.section-body {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 25rem 34rem 25rem;
  gap: 2rem 0;
}

.register-plan {
  width: 100%;
  height: 27rem;
  background-color: var(--gray-100);
  border: 2px solid var(--gray-200);
  margin: 0 auto;
  border-radius: var(--radius);
  padding: 0.5rem;
  max-width: 330px;
}

.plan-cell2 {
  height: 34rem;
  position: relative;
}

.plan-cell1,
.plan-cell2,
.plan-cell3 {
  transition: var(--transition-2);
}

.plan-cell1.visited,
.plan-cell2.visited,
.plan-cell3.visited {
  transform: scale(1.01);
  outline: 2px solid var(--primary-500);
  outline-offset: 0.15rem;
}

.plan-cell1,
.plan-cell3 {
  background-color: var(--gray-800);
  border-color: var(--gray-900);
}

.plan-cell1 .section-title,
.plan-cell3 .section-title {
  color: var(--primary-400);
}

.plan-cell2 .plan-title {
  margin-top: 1.5rem;
}

.plan-cell1 p,
.plan-cell3 p {
  color: var(--gray-300) !important;
}

.plan-cell1 ul li,
.plan-cell3 ul li {
  color: var(--gray-200) !important;
}

.plan-price {
  background-color: var(--gray-800);
  border-radius: var(--radius);
  height: 2.25rem;
  margin: 0.5rem auto !important;
  max-width: 18rem;
}

.plan-price span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  gap: 0 0.25rem;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: var(--transition-2);
}

.plan-price p,
.plan-price i {
  margin: 0 !important;
  font-size: 1.25rem;
  color: var(--gray-50) !important;
}

.plan-price p {
  font-family: var(--ff-body);
  font-weight: 600 !important;
  letter-spacing: var(--letter-spacing);
  transition: var(--transition-2);
}

.plan-cell2 .btn-plan {
  text-wrap: wrap;
  width: 80%;
  background-color: var(--primary-500) !important;
  color: var(--gray-50) !important;
}

.plan-cell2 .btn-plan:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
}

.plan-cell2 .fa-crown {
  color: gold;
  position: absolute;
  top: -1.75rem;
  right: -1.75rem;
  font-size: 3rem !important;
  transform: rotate(45deg);
}

.plan-cell1 .btn-plan,
.plan-cell3 .btn-plan {
  background-color: transparent !important;
  color: var(--gray-50) !important;
  border: 2px solid var(--primary-400) !important;
}

.plan-cell1 .btn-plan:hover,
.plan-cell3 .btn-plan:hover {
  background-color: var(--gray-50) !important;
  color: var(--primary-500) !important;
  border: 2px solid var(--gray-50) !important;
}

.register-plan .fa-square-check {
  /* color: green; */
  color: var(--green-mid);
}

.register-plan ul li {
  color: var(--gray-900);
  font-family: var(--ff-body);
  font-weight: 600 !important;
  letter-spacing: var(--letter-spacing);
  text-transform: capitalize;
  font-size: 0.9rem;
}

.register-plan .section-title {
  font-weight: 600 !important;
}

.register-plan p {
  max-width: 15rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--gray-500);
  font-family: var(--ff-body);
  letter-spacing: var(--letter-spacing) !important;
}

.register-plan ul {
  list-style-type: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.plan-title {
  margin-top: 0.5rem;
  font-size: 1.5rem !important;
}

.btn-plan {
  color: var(--primary-500) !important;
  background-color: transparent !important;
  border: 2px solid var(--primary-500) !important;
  font-family: var(--ff-body);
  font-size: 1rem !important;
  font-weight: 600 !important;
  transition: var(--transition-2);
}

.btn-plan:hover {
  color: var(--gray-50) !important;
  background-color: var(--primary-500) !important;
  border: 2px solid var(--primary-500) !important;
}

.animation-parent {
  grid-column: 1/-1;
  grid-row: 10;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
}

.register-enterprise {
  background-color: var(--gray-50);
  width: 90vw;
  /* height: 32rem; */
  height: 50rem;
  min-height: auto;
  max-width: 1440px;
  max-height: 60rem;
  border-radius: var(--radius);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1);

  transition: var(--transition-2);
}

.register-container {
  background-color: var(--gray-50);
  width: 90vw;
  /* height: 32rem; */
  height: 50rem;
  min-height: auto;
  max-width: 1440px;
  max-height: 60rem;
  border-radius: var(--radius);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1);

  transition: var(--transition-2);
}

.register-hide {
  display: none !important;
}

.register-show {
  display: grid !important;
}

.register-go-back {
  position: absolute;
  font-size: 1.5rem;
  z-index: 10;
  top: 0rem;
  left: 0.5rem;
  margin: 0 !important;
  cursor: pointer;
}

.register-go-back:hover {
  color: var(--primary-500) !important;
}

.register-plans-hide {
  display: none !important;
}

.register-plans-show {
  display: flex !important;
}

.register-container:hover {
  /* transform: scale(1.01); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1);
}

.register-enterprise:hover {
  /* transform: scale(1.01); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2), 0px 0px 20px rgba(0, 0, 0, 0.1),
    0px 0px 30px rgba(0, 0, 0, 0.1);
}

.register-col-1 {
  display: none;
  height: 100%;
  width: 100%;
}

.enterprise-col-1 {
  display: none;
  height: 100%;
  width: 100%;
}

.register-tos {
  margin: 1rem 0 !important;
  color: var(--gray-500);
  font-family: var(--ff-body);
  letter-spacing: var(--letter-spacing);
}

.register-tos a:hover {
  text-decoration: none;
  color: var(--primary-500) !important;
}

.register-col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1rem;
  position: relative;
  overflow: hidden;
}

.enterprise-col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 1rem 1rem;
  position: relative;
  overflow: hidden;
}

.register-banner h1 {
  text-transform: capitalize;
  font-family: var(--ff-heading);
  font-weight: 600;
  color: var(--primary-500);
  letter-spacing: var(--letter-spacing);
}

.register-banner p {
  font-family: var(--ff-body);
  font-weight: 200;
  color: var(--gray-500);
  line-height: 1.25;
}

.register-body {
  width: 100%;
  overflow-y: auto;
  padding: 0.25rem;
}

.register-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.register-body::-webkit-scrollbar-thumb {
  background-color: var(--gray-100);
  border-radius: 10px;
}

.register-body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  margin-left: 1rem !important;
}

.register-form-input-row {
  background-color: var(--gray-100);
  border: 1px solid transparent;
  outline: none;
  margin: 0;
  transition: var(--transition-2);
  text-align: center;
  padding-top: 0.5rem;
  cursor: text;
  max-width: 20rem;
  margin: 0 auto;

  position: relative;

  border-radius: var(--radius);
  margin-bottom: 1rem;
}

.register-form-email-row {
  /* border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius); */
}

.register-form-input-row.valid {
  background-color: var(--green-light) !important;
}

.register-form-input-row.not-valid {
  background-color: var(--red-light) !important;
}

.register-form-password-row.default {
  background-color: var(--gray-100);
}

.register-label-focused,
.register-i-focused {
  color: var(--primary-500) !important;
  transition: var(--transition-2);
}

.register-form-password-row {
  /* border-bottom-left-radius: var(--radius);
    border-bottom-right-radius: var(--radius); */
  /* margin-bottom: 1rem; */
}

.register-row-focused {
  background-color: var(--gray-200);
}

.register-form-input-row .form-register-input-icon {
  color: var(--gray-700);
  font-size: 1rem;
  display: block;
  position: absolute;
  top: 20%;
  left: 1rem;
}

.register-form-input-row label {
  color: var(--gray-700);
  font-size: 1rem;
  font-family: var(--ff-body);
  font-weight: 100;
  letter-spacing: var(--letter-spacing);
  display: block;
  cursor: text;
  text-transform: capitalize;
}

.register-form-input-row input,
.register-form-input-row textarea {
  border: 1px solid transparent;
  background-color: transparent;
  transition: var(--transition-2);
  width: 90%;
  letter-spacing: 0.15rem;
  text-align: center;
}

.register-form-input-row input:focus,
.register-form-input-row textarea:focus {
  border: 1px solid transparent;
  outline: none;
}

.password-visibility {
  display: inline-block !important;
  margin-left: 0.25rem;
  cursor: pointer;
  transition: var(--transition-2);
  font-size: 1.25rem;
}

.password-visibility:hover {
  color: var(--gray-900);
}

#registerPassword,
#registerConfirmPassword {
  width: 80% !important;
}

.register-form-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 1rem;
}

.register-form-btn {
  border-radius: var(--radius-half-circle) !important;
  font-weight: 600 !important;
  margin: 0 !important;
  font-size: 0.8rem;
}

.register-form-submit:hover {
  background-color: transparent !important;
  color: var(--primary-500) !important;
  transform: scale(1.01) !important;
  border: 2px solid var(--primary-500) !important;
  font-family: var(--ff-body);
}

.register-form-register {
  background-color: var(--gray-900) !important;
  color: var(--gray-50) !important;
  border: 2px solid var(--gray-900) !important;
}

.register-form-register:hover {
  background-color: var(--gray-100) !important;
  color: var(--gray-900) !important;
  border: 2px solid var(--gray-900) !important;
}

.register-form-forgot-password {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--gray-900) !important;
  font-size: 0.75rem !important;
  margin: 0 !important;
  font-family: var(--ff-body) !important;
  font-weight: 600 !important;
  margin-top: 1rem !important;
}

.register-form-forgot-password:hover {
  color: var(--primary-500) !important;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px var(--gray-200) inset;
  background-color: none; /* Change the background color */
  border: 1px solid var(--gray-200); /* Change the border color */
}

.form-alert-register {
  margin: 0 !important;
  margin-top: 1rem !important;
}

.register-switches {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.register-switches .form-row-condition-register {
  margin: 0 auto !important;
  /* width: 20rem!important; */
  /* justify-content: space-between!important; */
}

@media screen and (min-width: 375px) {
  .register-go-back {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 768px) {
  .register-switches .form-row-condition-register {
    width: 21rem !important;
    justify-content: space-between !important;
  }

  .register-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7, auto);
    gap: 0 1rem;
  }

  .register-form-input-row {
    width: 100%;
  }

  #registerRowFirstName,
  #registerRowLastName {
    grid-row: 1;
    grid-column: 1;
  }

  #registerRowLastName {
    grid-column: 2;
  }

  #registerRowEmail {
    grid-row: 2;
    grid-column: 1;
  }

  #registerRowMobileNumber {
    grid-row: 2;
    grid-column: 2;
  }

  #registerRowPassword {
    grid-row: 3;
    grid-column: 1;
  }

  #registerRowConfirmPassword {
    grid-row: 3;
    grid-column: 2;
  }

  #registerRowDateOfBirth {
    grid-row: 4;
    grid-column: 1 / -1;
  }

  #registerRowLeadMessage {
    grid-row: 4;
    grid-column: 1 / -1;
  }

  .register-switches {
    margin-top: 1rem;
    grid-row: 5;
    grid-column: 1 / -1;
  }

  .register-tos {
    grid-row: 6;
    grid-column: 1 / -1;
  }

  .register-form-btns {
    grid-row: 9;
    grid-column: 1 / -1;
  }

  .register-go-back {
    font-size: 2rem;
  }

  .section-register-plans .section-banner p {
    max-width: 40rem;
  }

  .register-form-btns {
    flex-direction: row;
  }

  .register-form-input-row .form-register-input-icon {
    top: 50%;
    left: 1rem;
    font-size: 1.5rem;
    transform: translateY(-50%);
  }

  .register-form-input-row input {
    width: 60% !important;
  }

  #registerPassword,
  #registerConfirmPassword {
    width: 60% !important;
  }

  .password-visibility {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
  }

  .plan-cell2 .fa-crown {
    /* top: 0.75rem;
        right: 0.75rem;
        font-size: 2.25rem!important; */
    /* 
        top: -1.75rem;
        right: -1.75rem; */
    font-size: 3rem !important;
  }
}

@media screen and (min-width: 1024px) {
  .main-register {
    background-size: 20rem;
  }

  .register-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
    width: 95vw;
  }

  .register-enterprise {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
    width: 95vw;
  }

  .register-col-1 {
    grid-column: 2;
    grid-row: 1;
    display: block !important;
    position: relative;

    opacity: 0;
    transform: translateX(-50%);
    transition: all 1s;
  }

  .enterprise-col-1 {
    grid-column: 2;
    grid-row: 1;
    display: block !important;
    position: relative;

    opacity: 0;
    transform: translateX(-50%);
    transition: all 1s;
  }

  .register-col-1.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .register-col-1 .register-wallpaper {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    position: absolute;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .enterprise-col-1.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .enterprise-col-1 .enterprise-wallpaper {
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    position: absolute;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .register-logo {
    width: 10rem;
    height: 9rem;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
  }

  .enterprise-logo {
    width: 10rem;
    height: 9rem;
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
  }

  .register-col-2 {
    grid-column: 1;
    grid-row: 1;

    opacity: 0;
    transform: translateX(50%);
    transition: all 1s;
  }

  .enterprise-col-2 {
    grid-column: 1;
    grid-row: 1;

    opacity: 0;
    transform: translateX(50%);
    transition: all 1s;
  }

  .register-col-2.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .enterprise-col-2.visible {
    opacity: 1;
    transform: translateX(0);
  }

  .register-form-btns-hebrew {
    flex-direction: row-reverse;
  }

  .register-plans {
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    gap: 2.5rem 1rem;
  }

  .plan-cell2 {
    grid-row: 2;
  }

  .section-plan-billing-duration {
    margin-bottom: 1rem;
  }

  .password-visibility {
    top: 70%;
    right: 1rem;
    transform: translateY(-70%);
  }
}

@media screen and (min-width: 1220px) {
  .register-plans {
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr;
    gap: 0 1rem !important;
  }

  .plan-cell1,
  .plan-cell2,
  .plan-cell3 {
    grid-row: 1 !important;
  }

  .password-visibility {
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
  }
}
