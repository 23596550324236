.progress-bar{
    height: 100vh;
    width: 25vw;
    background-color: var(--gray-200);
    border-right: 2px solid var(--gray-300);
    position: fixed;

    transform: translateX(-100%);
    transition: var(--transition-2);
    max-width: 5rem;
    overflow: auto;

    z-index: 2000;  

}

.progress-bar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
.progress-bar::-webkit-scrollbar-thumb {
    background-color: var(--primary-500);
}

.progress-bar::-webkit-scrollbar-track {
    background-color: var(--gray-200);
}

.progress-bar.visible{
    transform: translateX(0);
}

.progress-bar ul{
    transition: var(--transition-2);
    height: 100%;
    padding: 1rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: start
}

.progress-bar-item{
    width: 40px;
    height: 40px;
    background-color: var(--gray-300);
    border-radius: 50%;
    border: 2px solid var(--gray-300);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-900);
    font-weight: 600;
    cursor: pointer;
    font-size: 1rem;
    transition: var(--transition-2);
}

.progress-bar-item:hover{
    background-color: var(--primary-300);
    border-color: var(--primary-300);
}

.current-question{
    background-color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
    color: var(--gray-50)!important;
}

.done-question{
    background-color: var(--green-mid);
    border-color: var(--green-mid);
    color: var(--gray-900);
}


.disable-progress-bar{
    pointer-events: none;
}

.study-finish-button{
    font-size: 3rem;
    transition: var(--transition-2);
    cursor: pointer;
    color: var(--gray-900);
}

.study-finish-button:hover{
    color: var(--primary-600);
}

.done-btn{
    padding-bottom: 0.5rem;
}

@media screen and (min-width: 1024px) {
    .progress-bar{
        transform: translateX(0);
    }
}