.mobile-verification{
    z-index: 10000;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: var(--gray-100);
    width: 20rem;

    opacity: 0;
    transform: translate(-10vw);
    transition: 0.3s ease-in-out all;

    padding: 1rem;
}

.mobile-verification.visible{
    opacity: 1;
    transform: translate(0);
    transition: 0.3s ease-in-out all;
}


.phone-number-div{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
}

.phone-number-div p{
    letter-spacing: var(--letter-spacing);
    font-size: 1.25rem;
    color: var(--gray-600);
    text-align: center;
    margin: 0!important;
}

.phone-number-div i{
    font-size: 1.25rem;
    color: var(--gray-600);
    text-align: center;
    margin: 0!important;
    cursor: pointer;
    transition: var(--transition-2);
}

.phone-number-div input {
    background-color: var(--gray-100);
    border: 2px solid var(--gray-200);
    border-radius: var(--radius);
    outline: 2px solid transparent;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    letter-spacing: var(--letter-spacing);
    color: var(--gray-600);
}

#codeInput {
    background-color: var(--gray-100);
    border: 2px solid var(--gray-200);
    border-radius: var(--radius);
    outline: 2px solid transparent;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
    letter-spacing: var(--letter-spacing);
    color: var(--gray-600);
    max-width: 90%
}

.phone-number-div .fa-check{
    position: absolute;
    left: 92%;
    transform: translateX(-92%);
}

.phone-number-div .fa-xmark{
    position: absolute;
    right: 92%;
    transform: translateX(92%);
}

.phone-number-div .fa-xmark:hover{
    color: var(--red-mid);
}

.phone-number-div i:hover{
    color: var(--primary-400);
}

.mobile-verify-btn{
    background-color: var(--primary-400)!important;
    color: var(--gray-50)!important;
    border: 2px solid var(--primary-400)!important;
    margin-top: 1rem!important;
}

.mobile-verify-btn:hover{
    background-color: transparent!important;
    border: 2px solid var(--primary-400)!important;
    color: var(--primary-400)!important;
}