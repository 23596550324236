.profile-main{
    min-height: calc(100vh - 5rem);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url("../../images/geometry2.png") 100%/contain repeat fixed;
    background-size: 12rem;
}

#userProfile{
    text-transform: capitalize;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.btn-upgrade-to-premium{
    margin-top: 1rem;
    color: var(--gray-50)!important;
}

.btn-upgrade-to-premium:hover{
    background-color: transparent!important;
    color: var(--primary-500)!important;
    border: 2px solid var(--primary-500)!important;
}

.profile-parent{
    max-width: var(--width-section);
    width: 100%;
}

.profile-info{
    display: grid;
    grid-template-rows: repeat(6, 50px);
    width: 100%;
    max-width: var(--width-section);
    margin: 0 auto;
}

.profile-info.reverse-to-he{
    direction: rtl;
}

.profile-info-cell, .profile-info-cell2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    min-height: 50px;
    transition: var(--transition-2);
}

.profile-info-cell{
    background-color: var(--gray-50);
    background-color: transparent;
    padding: 0 1rem;
}

.profile-info-cell2{
    background-color: var(--gray-100);
    background-color: var(--gray-100);
    padding: 0 1rem;
}

.profile-info-cell:hover, .profile-info-cell2:hover{
    transform: scale(1.02);
    box-shadow: var(--shadow-2);
}

.profile-info-col1{
    display: flex;
    justify-content: start;
    align-items: center;
    text-transform: capitalize;
    text-align: right;
}

.profile-info-col2{
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
}

.verification-pending{
    color: var(--yellow-mid);
}

.verification-failed{
    color: var(--red-mid);
}

.verification-verified{
    color: var(--green-mid);
}