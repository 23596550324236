#examPauseScreen{
    width: 100vw;
    z-index: 5000;
    background-color: var(--gray-50);
    opacity: 1;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
}

.btn-end-exam{
    background-color: transparent!important;
    color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
    margin-bottom: 0!important;
}

.btn-end-exam:hover{
    background-color: var(--black-mid)!important;
    color: var(--gray-50)!important;
    border-color: var(--black-mid)!important;
}

#pauseScreenPanel{
    width: 20rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#pauseScreenPanel h1{
    font-size: 2.5rem;
    color: var(--primary-500);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
}

#pauseScreenPanel h3{
    font-size: 1.5rem;
    color: var(--primary-400);
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.pause-menu-title{
    color: var(--primary-400);
    margin-top: 1rem;
}

#pauseScreenPanel p{
    font-size: 1.25rem;
    color: var(--gray-900);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing);
    font-weight: 600;
    margin: 1.5rem 0 0.5rem 0!important;
}

#pauseScreenPanel ul{
    list-style: none;
    font-size: 1rem;
    color: var(--gray-900);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing);
    font-weight: 500;
    font-family: var(--ff-body);
}

#pauseScreenPanel ul li span{
    font-weight: 600;
}

.btn-start-screen:hover{
    background-color: transparent!important;
    color: var(--primary-500)!important;
    border-color: var(--primary-500)!important;
}

.btn-start-screen2{
    left: 50%;
    position: absolute;
    transform: translate(-50%, -1%);
    top: 1%;

    background-color: transparent!important;
    color: var(--red-mid)!important;
    border-color: var(--red-mid)!important;

    display: none!important;

    margin-top: 1rem;
}

.btn-start-screen2:hover{
    background-color: var(--red-mid)!important;
    color: var(--gray-50)!important;
    border-color: var(--red-mid)!important;
}

.btn-start-screen2-icon{
    position: absolute;

    background-color: transparent!important;
    color: var(--red-mid)!important;
    border-color: var(--red-mid)!important;

    left: 3%;
    top: 3%;
    transform: translate(-3%,-3%);
    font-size: 2rem;

}

.btn-start-screen2-icon:hover{
    background-color: var(--red-mid)!important;
    color: var(--gray-50)!important;
    border-color: var(--red-mid)!important;
}

#startExamBtn.gray-out-btn{
    cursor: not-allowed!important;
    background: var(--primary-700)!important;
    color: var(--gray-500)!important;
}

#startExamBtn.gray-out-btn:hover{
    cursor: not-allowed!important;
    background: var(--primary-800)!important;
    color: var(--gray-500)!important;
}

#pauseScreenButtons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.abort-exam-p, .abort-exam-p-start{
    color: var(--red-mid)!important;
    cursor: pointer;
    transition: var(--transition-2);
}

.abort-exam-p-start{
    margin-top: 0.25rem!important;

}

.abort-exam-p:hover, .abort-exam-p-start:hover{
    color: var(--red-dark)!important;
    cursor: pointer;
}

@media screen and (min-width: 425px){
    #pauseScreenPanel {
        width: 25rem;
    }

    #pauseScreenPanel h3{
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 425px){
    #pauseScreenPanel {
        width: 35rem;
    }
}

@media screen and (min-width: 768px){
    .btn-start-screen2{
        display: block!important;
    }

    .btn-start-screen2-icon{
        display: none;
    }
}
