#FilterExamsDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.fa-trash-can{
    transition: var(--transition-2);
}

.fa-trash-can:hover{
    color: var(--red-mid);
}

.delete-filter{
    transition: var(--transition-2);
}

.delete-filter:hover{
    color: var(--red-mid);
}

.filter-element-selecter{
    color: #007bff;
}